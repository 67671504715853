import React from 'react';


function Overview() {
	return (
		<div className="hairways-root">
			Overview
		</div>
	);
}

export default Overview;
