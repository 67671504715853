import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material';

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3,
	maxHeight: '80vh', // Set a maximum height for the modal
	overflowY: 'auto', // Enable vertical scrolling if content overflows
};

const styleForLarge = {
	...style,
	width: '90%',
};

interface GenericModalProps {
	open: boolean;
	close?: () => void;
	title: string;
	children: React.ReactNode;
	sx?: SxProps<Theme>;
}

const GenericModal = (props: GenericModalProps) => {
	return (
		<>
			<Modal
				open={props.open}
				onClose={props.close}
				aria-labelledby="child-modal-title"
				aria-describedby="child-modal-description"
			>
				<Box sx={{ ...style }}>
					<h2 id="child-modal-title">{props.title}</h2>
					{props.children}
				</Box>
			</Modal>
		</>
	);
};

export const GenericModalV2 = (props: GenericModalProps) => {
	return (
		<>
			<Modal
				open={props.open}
				onClose={props.close}
				aria-labelledby="child-modal-title"
				aria-describedby="child-modal-description"
			>
				<Box sx={{ ...styleForLarge }} >
					<h2 id="child-modal-title">{props.title}</h2>
					{props.children}
				</Box>
			</Modal>
		</>
	);
}


export const withModal = <P extends object>(Component: React.ComponentType<P>) => {
	const WithModalComponent = (props: GenericModalProps & P) => {
		const { open, close, title } = props;
		return (
			<GenericModal open={open} title={title} close={close}>
				<Component {...props} />
			</GenericModal>
		)
	}
	return WithModalComponent as React.ComponentType<P>;
}

export default GenericModal;
