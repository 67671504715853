
import { SyntheticEvent } from 'react';
import { Button, SvgIcon } from '@mui/material';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';

interface AddButtonComponentProps {
	label?: string;
	onClick?: (event: SyntheticEvent) => void;
}

export const AddButtonComponent = (props: AddButtonComponentProps) => {
	return (
		<Button
			startIcon={
				<SvgIcon fontSize="small">
					<PlusIcon />
				</SvgIcon>
			}
			variant="contained"
			onClick={props?.onClick}
		>
			Add {props?.label}
		</Button>
	);
};
