import { useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Box, Container } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';

import { useSelection } from '../../hooks/use-selection';
import { GenericTable, Column } from '../../components/generic/generic.table';
import { DATE_FORMAT } from '../../constants';

import EditUser from '../../components/generic/edit.user';

import { useQuery, useMutation } from '@apollo/client';
import { useShopDetails } from '../../hooks/use-shop';
import { DELELE_USER, LIST_USERS } from '../../gql/queries';
import { User } from '../../gql/graphql';



const useCustomerIds = (users: any) => {
	return useMemo(
		() => {
			return users.map((user: any) => user.id);
		},
		[users]
	);
};

const columns: Column[] = [
	{ title: 'First Name', key: 'firstName' },
	{ title: 'Last Name', key: 'lastName' },
	{ title: 'Phone', key: 'phone' },
	{ title: 'Email', key: 'email' },
	{ title: 'Created', key: 'createdAt', formatter: (column: Column, data: any) => { return format(new Date(data[column.key]), DATE_FORMAT); } },
];
function UserList(props: any) {
	const { shopId, locationId } = useShopDetails();
	const { data, refetch } = useQuery(LIST_USERS, { variables: { shopId, locationId } });

	const users: any[] = useMemo(
		() => {
			return data?.users ? data?.users : []
		},
		[data]
	);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	// const users = useCustomers(page, rowsPerPage, data?.listCustomer);
	const usersIds = useCustomerIds(users);
	const usersSelection = useSelection(usersIds);

	const handlePageChange = useCallback(
		(event: any, value: any) => {
			setPage(value);
		},
		[]
	);

	const handleRowsPerPageChange = useCallback(
		(event: any) => {
			setRowsPerPage(event.target.value);
		},
		[]
	);

	const [deleteUser] = useMutation(DELELE_USER);

	const handleDeleteOne = useCallback(
		async (user: User) => {
			try {
				await deleteUser({ variables: { userId: user._id, shopId } });
				toast.success(`Deleted ${user.firstName} ${user.lastName} successfully.`);
				await refetch();
			} catch (e: any) {
				toast.error(e.message);
			}
		},
		// eslint-disable-next-line
		[]
	);


	return (
		<>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8
				}}
			>
				<Container maxWidth="xl">
					<GenericTable
						title='Users'
						data={users}
						columns={columns}
						onDeselectAll={usersSelection.handleDeselectAll}
						onDeselectOne={usersSelection.handleDeselectOne}
						onPageChange={handlePageChange}
						onRowsPerPageChange={handleRowsPerPageChange}
						onSelectAll={usersSelection.handleSelectAll}
						onSelectOne={usersSelection.handleSelectOne}
						onDeleteOne={handleDeleteOne}
						page={page}
						rowsPerPage={rowsPerPage}
						selected={usersSelection.selected}
						addEditComponent={EditUser}
						refetch={refetch}
						showSelectCheckBox={props.isModal}
					/>

					{props.isModal && <CardActions sx={{ justifyContent: 'flex-end' }} >
						<Button variant="outlined" onClick={() => { props.onClose() }} >
							Cancel
						</Button>
						<LoadingButton disabled={!usersSelection.selected} variant="contained" onClick={() => { props.onClose(usersSelection.selected) }}>
							Attach Selected
						</LoadingButton>
					</CardActions>}
				</Container>
			</Box>
		</>
	);
}

export default UserList;
