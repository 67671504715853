import { createContext, useContext, useEffect, useReducer } from 'react';
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/react-hooks";
import { isExpired, decodeToken } from 'react-jwt';
import { Shop } from '../gql/graphql';

interface JwtDecoded {
	email: string
	user_id: string
};

interface ShopDetails {
	shopId: string;
	locationId: string;
	shopName: string;
	locationName: string;
}

type AuthActions =
	| { type: 'SIGN_IN', value: JwtDecoded  }
	| { type: 'SIGN_OUT' }
	| { type: 'SET_JWT', value: JwtDecoded }
	| { type: 'SET_SHOP', value: ShopDetails }
	| { type: 'SET_SHOP_DETAILS', value: Shop };

type AuthState = {
	signIn: (jwt: string) => void
	signOut: () => void,
	setJwt: (jwt: string) => void,
	setShop: (shopDetails: ShopDetails) => void,
	setShopDetails: (shopDetails: Shop) => void,
	isAuthenticated: boolean;
	jwtDecoded: JwtDecoded | null,
	shopId: string,
	locationId: string;
	shopName: string;
	locationName: string;
	shopDetails: Shop | null;
	
};

const jwt = String(localStorage.getItem('jwt'));
const shopId = String(localStorage.getItem('shopId'));
const locationId = String(localStorage.getItem('locationId'));
const isAuthenticated = !isExpired(jwt) && shopId!=="" && locationId!=="";

const initialState: AuthState = {
	isAuthenticated,
	jwtDecoded: jwt ? decodeToken(jwt) : null,
	shopId, 
	locationId,
	shopName: String(localStorage.getItem('shopName')),
	locationName: String(localStorage.getItem('locationName')),
	shopDetails: null,
} as AuthState;


const reducer = (state: AuthState, action: AuthActions): AuthState => {
	switch (action.type) {
		case "SIGN_IN":
			return {
				...state,
				jwtDecoded: action.value,
				isAuthenticated: true
			}
		case "SIGN_OUT":
			return {
				...state,
				isAuthenticated: false,
				jwtDecoded: null,
				shopId: '',
				locationId: ''
			}
		case "SET_JWT":
			return {
				...state,
				jwtDecoded: action.value
			}
		case "SET_SHOP":
			return {
				...state,
				isAuthenticated: !isExpired(String(localStorage.getItem('jwt'))) && action.value.shopId!=="" && action.value.locationId!=="",
				shopId: action.value.shopId,
				locationId: action.value.locationId,
				shopName: action.value.shopName,
				locationName: action.value.locationName,
			}
		case "SET_SHOP_DETAILS":
			return {
				...state,
				shopDetails: action.value
			}
		default:
			return state;
	}
};

// The role of this context is to propagate authentication state through the App tree.

export const AuthContext = createContext<AuthState | undefined>(undefined);

interface AuthProviderProps {
	children: React.ReactNode;
}

interface AuthProviderProps {
	children: React.ReactNode
};

export const AuthProvider = (props: AuthProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const apolloClient = useApolloClient();

	const signIn = async (jwt: string) => {
		dispatch({
			type: 'SIGN_IN',
			value: decodeToken(jwt) as JwtDecoded
		});
		navigate('/dashboard');
	};

	const setJwt = (jwt: string) => {
		localStorage.setItem('jwt', jwt);
		dispatch({
			type: 'SET_JWT',
			value: decodeToken(jwt) as JwtDecoded
		});
	};

	const setShop = (shopDetails: ShopDetails) => {
		localStorage.setItem('shopId', shopDetails.shopId);
		localStorage.setItem('locationId', shopDetails.locationId);
		localStorage.setItem('locationName', shopDetails.locationName);
		localStorage.setItem('shopName', shopDetails.shopName);
		dispatch({
			type: 'SET_SHOP',
			value: shopDetails
		});
	};

	const setShopDetails = (shopDetails: Shop) => {
		dispatch({
			type: 'SET_SHOP_DETAILS',
			value: shopDetails
		});
	};

	const signOut = async () => {
		localStorage.setItem('jwt', '');
		localStorage.setItem('shopId', '');
		localStorage.setItem('locationId', '');
		await apolloClient.clearStore();
		dispatch({
			type: 'SIGN_OUT'
		});
	};

	const navigate = useNavigate();
	useEffect(() => {
		if (!state.isAuthenticated) {
			navigate('/login');
		}
		// eslint-disable-next-line
	}, [state.isAuthenticated]);

	return (
		<AuthContext.Provider
			value={{ ...state, signIn, signOut, setJwt, setShop, setShopDetails }}
		>
			{props.children}
		</AuthContext.Provider>
	);
};


export const useAuthContext = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useAuthContext must be used within an AuthProvider');
	}
	return context;
};
