import { format } from "date-fns";
import { ISO8601Duration } from "graphql-scalars/typings/scalars/iso-date/Duration";
import * as Sentry from "@sentry/react";

export const getDate = (date: Date | ISO8601Duration) => {
	let result = "";
	try {
		result = format(new Date(date), "dd/MM/yyyy");
	} catch (error) {
		Sentry.captureException(error);
	}
	return result;
}

export const getTime = (date: Date | ISO8601Duration) => {
	let result = "";
	try {
		result = format(new Date(date), "hh:mm a");
	} catch (error) {
		Sentry.captureException(error);
	}
	return result;
}

export const getDateTime = (date: Date | ISO8601Duration) => {
	let result = "";
	try {
		result = format(new Date(date), "dd/MM/yyyy hh:mm a");
	} catch (error) {
		Sentry.captureException(error);
	}
	return result;
}