import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { gql, useQuery } from '@apollo/client';
import {
	Stack
} from '@mui/material';
import { SelectComponent } from '../select';
import { useMemo } from 'react';
import { useAuthContext } from '../../contexts/auth-context';

interface ShopSelectorProps {
}

const GET_USER_SHOP_ACCESS = gql`
query GetUserShopAccess {
	getUserShopAccess {
		_id
		name,
		location {
			_id
			address {
				city
			}
		}
	}
}`;

type Location = {
	_id: string;
	address: {
		city: string;
	}
}

type Shop = {
	_id: string;
	name: string;
	location: Location[]
}

export const ShopSelector = (props: ShopSelectorProps) => {

	const [selectedShop, setSelectedShop] = React.useState<Shop | null>(null);
	const [selectedLocation, setLocation] = React.useState<Location | null>(null);

	const { setShop } = useAuthContext();
	const { data } = useQuery(GET_USER_SHOP_ACCESS, {});
	const shops = useMemo(() => {
		if (data?.getUserShopAccess) {
			return data.getUserShopAccess.map((item: { _id: string, name: string }) => ({ label: item.name, value: item }));
		}
		return [];
	}, [data?.getUserShopAccess]);

	const locations = useMemo(() => {
		if (selectedShop) {
			return selectedShop?.location.map((item: { _id: string, address: { city: string } }) => ({ label: item.address.city, value: item }));
		}
		return [];
	}, [selectedShop]);

	return (
		<form
			noValidate
			onSubmit={(event: React.SyntheticEvent) => {
				event.preventDefault();
				setShop({ shopId: (selectedShop!)._id, locationId: (selectedLocation!)._id, shopName: (selectedShop!).name, locationName: (selectedLocation!).address.city })
			}}
		>
			<Stack>
				<SelectComponent data={shops} label={'Shop'} onChange={(value: Shop) => { setSelectedShop(value) }} />
			</Stack>
			{selectedShop && <Stack sx={{ mt: 1 }}>
				<SelectComponent data={locations} label={'Location'} onChange={(value: Location) => { setLocation(value) }} />
			</Stack>}
			<LoadingButton
				fullWidth
				size="large"
				sx={{ mt: 3 }}
				type="submit"
				variant="contained"
				disabled={!selectedShop || !selectedLocation}
			>
				Submit
			</LoadingButton>
		</form>
	)
}
