import { Location, OrderInput, OrderProductInput, OrderServiceInput } from "../gql/graphql";
import { GET_PRINTER_NAME } from "../gql/queries";
import { apolloClient } from "../App";

function getDiscountAmount(
	total: number,
	discountType: string,
	discountValue: number = 0
) {
	if (discountType === "PERCENTAGE") {
		return Math.round(total * (discountValue / 100));
	} else if (discountType === "AMOUNT") {
		return discountValue;
	}
	return 0;
}

export function calculateProductDiscount(products: OrderProductInput[]) {
	return products.map((product: OrderProductInput) => {
		product.discountAmount = getDiscountAmount(
			product.total!,
			product.discountType!,
			product.discountValue!
		);
		return product;
	});
}

export function calculateServiceDiscount(services: OrderServiceInput[]) {
	return services.map((service: OrderServiceInput) => {
		service.discountAmount = getDiscountAmount(
			service.total!,
			service.discountType!,
			service.discountValue!
		);
		return service;
	});
}

export function calculateOrderTotal(order: OrderInput) {
	const { products, services } = order;
	let subtotal = 0;
	let totalDiscount = 0;
	products?.forEach((product: OrderProductInput) => {
		subtotal += product.total!;
		totalDiscount += product.discountAmount! || 0;
	});

	services?.forEach((service: OrderServiceInput) => {
		subtotal += service.total!;
		totalDiscount += service.discountAmount! || 0;
	});
	return [{ label: "Subtotal", value: subtotal }, { label: "Discount", value: totalDiscount }, { label: "Total", value: subtotal - totalDiscount } ]
}

export const getAddress = (location: Location): string => {
	const { city, landmark, state, street, zipcode } = location.address;
	return `${street ?? ""}, ${landmark ?? ""}, ${city ?? ""}, ${
		state ?? ""
	} - ${zipcode}`;
};

export const setUpPrinter = async (shopId: string, locationId: string) => {
	if (localStorage.getItem("printer-name")) return;
	const { data } = await apolloClient.query<Record<"printerName", string>>({
		query: GET_PRINTER_NAME,
		variables: { shopId, locationId },
	});
	localStorage.setItem("printer-name", data?.printerName ?? "Default");
}