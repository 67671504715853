import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';
import { Card, InputAdornment, OutlinedInput, SvgIcon } from '@mui/material';


interface GenericSearchProps {
	onChange: (value: string | null) => void;
}

export const GenericSearch = (props: GenericSearchProps) => {

	const handleOnChange = (event: any) => {
		props.onChange(event.target?.value ? event.target.value : null);
	};

	return (
		<Card sx={{ p: 2 }}>
			<OutlinedInput
				defaultValue=""
				fullWidth
				placeholder="Search"
				onChange={handleOnChange}
				startAdornment={(
					<InputAdornment position="start">
						<SvgIcon
							color="action"
							fontSize="small"
						>
							<MagnifyingGlassIcon />
						</SvgIcon>
					</InputAdornment>
				)}
				sx={{ maxWidth: 500 }}
			/>
		</Card>
	)
}
