import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PRINTER_NAME, SAVE_PRINTER_NAME } from "../../gql/queries";
import { useShopDetails } from "../../hooks/use-shop";
interface PrinterSettingsProps { }

export const PrinterSettings = (props: PrinterSettingsProps) => {
	const [selectedValue, setSelectedValue] = useState("");
	const { shopId, locationId } = useShopDetails();
	const { data } = useQuery(GET_PRINTER_NAME, {
		variables: { shopId, locationId },
	});
	const [savePrinterName] = useMutation(SAVE_PRINTER_NAME);
	const handleChange = (event: any) => {
		setSelectedValue(event.target.value);
	};

	useEffect(() => {
		if (data && data?.printerName) {
			setSelectedValue(data?.printerName);
		}
	}, [data]);

	const handleSave = async () => {
		await savePrinterName({
			variables: { printerName: selectedValue, shopId, locationId },
		});
		localStorage.setItem("printer-name", selectedValue);
		toast.success("Printer name saved");
	};

	return (
		<Stack spacing={4}>
			<Card>
				<CardContent>
					<Grid container spacing={3}>
						<Grid xs={12} md={4}>
							<Typography variant="h6">Printer Name</Typography>
						</Grid>
						<Grid xs={12} sm={12} md={8}>
							<Stack alignItems="center" direction="row" spacing={3}>
								<TextField
									disabled={false}
									label="Printer Name"
									type="printer-name"
									value={selectedValue}
									onChange={handleChange}
									sx={{
										flexGrow: 1,
										...(!false && {
											"& .MuiOutlinedInput-notchedOutline": {
												borderStyle: "dotted",
											},
										}),
									}}
								/>
								<Button onClick={handleSave}>Save</Button>
							</Stack>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Stack>
	);
};
