import PropTypes from 'prop-types';
import BellIcon from '@heroicons/react/24/solid/BellIcon';
import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
import {
	Avatar,
	Badge,
	Box,
	IconButton,
	Stack,
	SvgIcon,
	Tooltip
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { usePopover } from '../../hooks/use-popover';
import { AccountPopover } from '../account-popover';

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

export const TopNav = (props: any) => {
	const { onNavOpen } = props;
	const lgUp = false;
	const accountPopover = usePopover();

	return (
		<>
			<Box
				component="header"
				sx={{
					backdropFilter: 'blur(6px)',
					backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
					position: 'sticky',
					left: {
						lg: `${SIDE_NAV_WIDTH}px`
					},
					top: 0,
					width: {
						lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
					},
					zIndex: (theme) => theme.zIndex.appBar
				}}
			>
				<Stack
					alignItems="center"
					direction="row"
					justifyContent="space-between"
					spacing={2}
					sx={{
						minHeight: TOP_NAV_HEIGHT,
						px: 2
					}}
				>
					<Stack
						alignItems="center"
						direction="row"
						spacing={2}
					>
						{!lgUp && (
							<IconButton onClick={onNavOpen}>
								<SvgIcon fontSize="small">
									<Bars3Icon />
								</SvgIcon>
							</IconButton>
						)}
						{/* <Tooltip title="Search">
							<IconButton>
								<SvgIcon fontSize="small">
									<MagnifyingGlassIcon />
								</SvgIcon>
							</IconButton>
						</Tooltip> */}
					</Stack>
					<Stack
						alignItems="center"
						direction="row"
						spacing={2}
					>
						{/* <Tooltip title="Contacts">
							<IconButton>
								<SvgIcon fontSize="small">
									<UsersIcon />
								</SvgIcon>
							</IconButton>
						</Tooltip> */}
						<Tooltip title="Notifications">
							<IconButton>
								<Badge
									badgeContent={4}
									color="success"
									variant="dot"
								>
									<SvgIcon fontSize="small">
										<BellIcon />
									</SvgIcon>
								</Badge>
							</IconButton>
						</Tooltip>
						<Avatar
							onClick={accountPopover.handleOpen}
							ref={accountPopover.anchorRef}
							sx={{
								cursor: 'pointer',
								height: 40,
								width: 40
							}}
							src="/assets/avatars/avatar-anika-visser.png"
						/>
					</Stack>
				</Stack>
			</Box>
			<AccountPopover
				anchorEl={accountPopover.anchorRef.current}
				open={accountPopover.open}
				onClose={accountPopover.handleClose}
			/>
		</>
	);
};

TopNav.propTypes = {
	onNavOpen: PropTypes.func
};
