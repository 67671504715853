import { createContext, useContext, useReducer } from "react";
import {
	DiscountType,
	OrderInput,
	OrderProductInput,
	OrderServiceInput,
} from "../gql/graphql";
import { calculateProductDiscount, calculateServiceDiscount } from "../utils/order.utils";

type Actions =
	| { type: "ADD_PRODUCT"; value: OrderProductInput }
	| { type: "ADD_SERVICE"; value: OrderServiceInput }
	| { type: "REMOVE_PRODUCT"; value: OrderProductInput }
	| { type: "REMOVE_SERVICE"; value: OrderServiceInput }
	| { type: "RESET_ORDER" }
	| {
			type: "UPDATE_PRODUCT_QUANTITY";
			value: { product: OrderProductInput; quantity: number };
		}
	| {
			type: "UPDATE_SERVICE_QUANTITY";
			value: { service: OrderServiceInput; quantity: number };
		}
	| {
			type: "UPDATE_SERVICE_DISCOUNT_TYPE";
			value: { service: OrderServiceInput; discountType: string };
		}
	| {
			type: "UPDATE_SERVICE_DISCOUNT";
			value: { service: OrderServiceInput; discountValue: number };
		}
	| {
			type: "UPDATE_PRODUCT_DISCOUNT_TYPE";
			value: { product: OrderProductInput; discountType: string };
		}
	| {
			type: "UPDATE_PRODUCT_DISCOUNT";
			value: { product: OrderProductInput; discountValue: number };
		}
	| {
		type: "UPDATE_STYLIST",
		value: { entity: OrderProductInput | OrderServiceInput; value: string, type: "SERVICE" | "PRODUCT" };
	};

type State = {
	addProduct: (product: OrderProductInput) => void;
	addService: (service: OrderServiceInput) => void;
	removeProduct: (product: OrderProductInput) => void;
	removeService: (service: OrderServiceInput) => void;
	resetOrder: () => void;
	updateProductQuantity: (product: OrderProductInput, quantity: number) => void;
	updateServiceQuantity: (service: OrderServiceInput, quantity: number) => void;
	updateServiceDiscountType: (
		service: OrderServiceInput,
		discountType: string
	) => void;
	updateServiceDiscount: (service: OrderServiceInput, discountValue: number) => void;
	updateProductDiscountType: (
		product: OrderProductInput,
		discountType: string
	) => void;
	updateStylist: (
		entity: OrderProductInput | OrderServiceInput,
		value: string,
		type: "SERVICE" | "PRODUCT"
	) => void;
	updateProductDiscount: (product: OrderProductInput, discountValue: number) => void;
	order: OrderInput;
};

const initialState: State = {
	addProduct: (product: OrderProductInput) => {},
	addService: (service: OrderServiceInput) => {},
	removeProduct: (product: OrderProductInput) => {},
	removeService: (service: OrderServiceInput) => {},
	resetOrder: () => {},
	updateProductQuantity: (product: OrderProductInput, quantity: number) => {},
	updateServiceQuantity: (service: OrderServiceInput, quantity: number) => {},
	updateServiceDiscountType: (
		service: OrderServiceInput,
		discountType: string
	) => {},
	updateServiceDiscount: (service: OrderServiceInput, discountValue: number) => {},
	updateProductDiscountType: (
		product: OrderProductInput,
		discountType: string
	) => {},
	updateProductDiscount: (product: OrderProductInput, discountValue: number) => {},
	updateStylist: (entity: OrderProductInput | OrderServiceInput, value: string, type: "SERVICE" | "PRODUCT") => {},
	order: {
		products: [],
		services: [],
		customerId: "",
		customerName: "",
		shopId: "",
		locationId: "",
		paymentMethod: "",
	},
} as State;



const reducer = (state: State, action: Actions): State => {
	switch (action.type) {
		case "ADD_PRODUCT": {
			const order = { ...state.order };
			if (!order.products) {
				order.products = [];
			} else {
				order.products = [...order.products];
			}

			order.products.push({
				name: action?.value?.name,
				price: action?.value?.price,
				total: action.value.price * action.value.quantity,
				productId: action.value.productId,
				quantity: action.value.quantity,
				discountAmount: action.value.discountAmount,
				discountType: action.value.discountType,
				discountValue: action.value.discountValue,
				stylistUserId: action.value.stylistUserId
			});

			return {
				...state,
				order,
			};
		}
		case "ADD_SERVICE": {
			const order = { ...state.order };
			if (!order.services) {
				order.services = [];
			} else {
				order.services = [...order.services];
			}

			order.services.push({
				name: action?.value?.name,
				price: action?.value?.price,
				total: action.value.price * action.value.quantity,
				serviceId: action.value.serviceId,
				quantity: action.value.quantity,
				discountAmount: action.value.discountAmount,
				discountType: action.value.discountType,
				discountValue: action.value.discountValue,
				stylistUserId: action.value.stylistUserId
			});

			return {
				...state,
				order,
			};
		}
		case "REMOVE_PRODUCT": {
			const order = { ...state.order };
			if (!order.products) {
				order.products = [];
			} else {
				order.products = [...order.products];
			}
			order.products = order.products.filter(
				(product: OrderProductInput) =>
					product !== action.value
			);
			return {
				...state,
				order,
			};
		}
		case "REMOVE_SERVICE": {
			const order = { ...state.order };
			if (!order.services) {
				order.services = [];
			} else {
				order.services = [...order.services];
			}
			order.services = order.services.filter(
				(service: OrderServiceInput) =>
					service !== action.value
			);
			return {
				...state,
				order,
			};
		}
		case "RESET_ORDER": {
			return {
				...state,
				order: {
					products: [],
					services: [],
					customerId: "",
					shopId: "",
					locationId: "",
					customerName: "",
					paymentMethod: "",
				},
			};
		}
		case "UPDATE_PRODUCT_QUANTITY": {
			const order = { ...state.order };
			if (!order.products) {
				order.products = [];
			} else {
				order.products = [...order.products];
			}
			order.products.map((product: OrderProductInput) => {
				if (product === action.value.product) {
					product.quantity = action.value.quantity;
					product.total = product.quantity * product.price;
				}
				return product;
			});
			order.products = calculateProductDiscount(order.products);
			return {
				...state,
				order,
			};
		}
		case "UPDATE_SERVICE_QUANTITY": {
			const order = { ...state.order };
			if (!order.services) {
				order.services = [];
			} else {
				order.services = [...order.services];
			}
			order.services.map((service: OrderServiceInput) => {
				if (service === action.value.service) {
					service.quantity = action.value.quantity;
					service.total = service.quantity * service.price;
				}
				return service;
			});

			order.services = calculateServiceDiscount(order.services);
			return {
				...state,
				order,
			};
		}
		case "UPDATE_SERVICE_DISCOUNT_TYPE": {
			const order = { ...state.order };
			if (!order.services) {
				order.services = [];
			} else {
				order.services = [...order.services];
			}
			order.services.map((service: OrderServiceInput) => {
				if (service === action.value.service) {
					if (action.value.discountType === "PERCENTAGE") {
						service.discountType = DiscountType.Percentage;
					} else if (action.value.discountType === "AMOUNT") {
						service.discountType = DiscountType.Amount;
					}
				}
				return service;
			});
			order.services = calculateServiceDiscount(order.services);
			return {
				...state,
				order,
			};
		}
		case "UPDATE_SERVICE_DISCOUNT": {
			const order = { ...state.order };
			if (!order.services) {
				order.services = [];
			} else {
				order.services = [...order.services];
			}
			order.services.map((service: OrderServiceInput) => {
				if (service === action.value.service) {
					service.discountValue = action.value.discountValue;
				}
				return service;
			});
			order.services = calculateServiceDiscount(order.services);
			return {
				...state,
				order,
			};
		}
		case "UPDATE_PRODUCT_DISCOUNT_TYPE": {
			const order = { ...state.order };
			if (!order.products) {
				order.products = [];
			} else {
				order.products = [...order.products];
			}
			order.products.map((product: OrderProductInput) => {
				if (product === action.value.product) {
					if (action.value.discountType === "PERCENTAGE") {
						product.discountType = DiscountType.Percentage;
					} else if (action.value.discountType === "AMOUNT") {
						product.discountType = DiscountType.Amount;
					}
				}
				return product;
			});
			order.products = calculateProductDiscount(order.products);
			return {
				...state,
				order,
			};
		}
		case "UPDATE_PRODUCT_DISCOUNT": {
			const order = { ...state.order };
			if (!order.products) {
				order.products = [];
			} else {
				order.products = [...order.products];
			}
			order.products.map((product: OrderProductInput) => {
				if (product === action.value.product) {
					product.discountValue = action.value.discountValue;
				}
				return product;
			});
			order.products = calculateProductDiscount(order.products);
			return {
				...state,
				order,
			};
		}
		case "UPDATE_STYLIST": {
			const order = { ...state.order };
			if (action.value.type === "SERVICE") {
				const serviceEntity = action.value.entity as OrderServiceInput;

				order.services.map((service: OrderServiceInput) => {
					if (service === serviceEntity) {
						service.stylistUserId = action.value.value;
					}
					return service;
				});
			} else if (action.value.type === "PRODUCT") {
				const productEntity = action.value.entity as OrderProductInput;
				order.products.map((product: OrderProductInput) => {
					if (product === productEntity) {
						product.stylistUserId = action.value.value;
					}
					return product;
				});
			}
			return {
				...state,
				order
			}
		}
		default:
			return state;
	}
};

export const OrderContext = createContext<State | undefined>(undefined);

interface OrderProviderProps {
	children: React.ReactNode;
}

interface OrderProviderProps {
	children: React.ReactNode;
}

export const OrderProvider = (props: OrderProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const addProduct = (hairproduct: OrderProductInput) => {
		dispatch({ type: "ADD_PRODUCT", value: hairproduct });
	};

	const addService = (hairservice: OrderServiceInput) => {
		dispatch({ type: "ADD_SERVICE", value: hairservice });
	};

	const removeProduct = (hairproduct: OrderProductInput) => {
		dispatch({ type: "REMOVE_PRODUCT", value: hairproduct });
	};

	const removeService = (hairservice: OrderServiceInput) => {
		dispatch({ type: "REMOVE_SERVICE", value: hairservice });
	};

	const resetOrder = () => {
		dispatch({ type: "RESET_ORDER" });
	};

	const updateProductQuantity = (
		product: OrderProductInput,
		quantity: number
	) => {
		dispatch({ type: "UPDATE_PRODUCT_QUANTITY", value: { product, quantity } });
	};

	const updateServiceQuantity = (
		service: OrderServiceInput,
		quantity: number
	) => {
		dispatch({ type: "UPDATE_SERVICE_QUANTITY", value: { service, quantity } });
	};

	const updateServiceDiscountType = (
		service: OrderServiceInput,
		discountType: string
	) => {
		dispatch({
			type: "UPDATE_SERVICE_DISCOUNT_TYPE",
			value: { service, discountType },
		});
	};

	const updateServiceDiscount = (
		service: OrderServiceInput,
		discountValue: number
	) => {
		dispatch({ type: "UPDATE_SERVICE_DISCOUNT", value: { service, discountValue } });
	};

	const updateProductDiscountType = (
		product: OrderProductInput,
		discountType: string
	) => {
		dispatch({
			type: "UPDATE_PRODUCT_DISCOUNT_TYPE",
			value: { product, discountType },
		});
	};

	const updateProductDiscount = (
		product: OrderProductInput,
		discountValue: number
	) => {
		dispatch({ type: "UPDATE_PRODUCT_DISCOUNT", value: { product, discountValue } });
	};


	const updateStylist = (
		entity: OrderProductInput | OrderServiceInput,
		value: string,
		type: "SERVICE" | "PRODUCT"
	) => {
		dispatch({ type: "UPDATE_STYLIST", value: { entity, value, type } });
	};

	return (
		<OrderContext.Provider
			value={{
				...state,
				addProduct,
				addService,
				removeProduct,
				removeService,
				resetOrder,
				updateProductQuantity,
				updateServiceQuantity,
				updateServiceDiscountType,
				updateServiceDiscount,
				updateProductDiscountType,
				updateProductDiscount,
				updateStylist,
			}}
		>
			{props.children}
		</OrderContext.Provider>
	);
};

export const useOrderContext = () => {
	const context = useContext(OrderContext);
	if (context === undefined) {
		throw new Error("useOrderContext must be used within an OrderProvider");
	}
	return context;
};
