import { useEffect, useMemo, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuthContext } from '../contexts/auth-context';
import { Phone } from '../components/login/phone';
import { Otp } from '../components/login/otp';
import { ShopSelector } from '../components/login/shop.selector';

import {
	Box,
	Stack,
	Typography,
	Button
} from '@mui/material';


type Steps = 'login' | 'otp' | 'selectshop';

function Login() {
	const { isAuthenticated, jwtDecoded, signOut } = useAuthContext();
	const navigate = useNavigate();
	useEffect(() => {
		isAuthenticated && navigate('/dashboard');
	}, [isAuthenticated])

	useEffect(() => {
		if (jwtDecoded?.user_id) {
			setStep('selectshop');
		}
	}, []);

	const [step, setStep] = useState<Steps>('login');
	const [phone, setPhone] = useState<string>('');


	const handlePhoneSubmit = useCallback(async (phone: string) => {
		setPhone(phone);
		setStep('otp');
	}, [step, phone]);

	const handleOTPSubmit = useCallback(async () => {
		setStep('selectshop');
	}, [step]);

	const title = useMemo(() => {
		switch(step) {
			case 'login':
				return 'Login with Phone Number';
			case 'otp':
				return 'Enter OTP';
			case 'selectshop':
				return 'Select Shop';
		}
	}, [step]);

	return (
		<>
			<Box
				sx={{
					backgroundColor: 'background.paper',
					flex: '1 1 auto',
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'center'
				}}
			>
				<Box
					sx={{
						maxWidth: 550,
						px: 3,
						py: '100px',
						width: '100%'
					}}
				>
					<div>
						<Stack
							spacing={1}
							sx={{ mb: 3 }}
						>
							<Typography variant="h4">
								{ title }
							</Typography>
						</Stack>
						{ step === 'login' && <Phone handleOnSubmit={handlePhoneSubmit}/> }
						{ step === 'otp' && <Otp phone={phone} handleOnSubmit={handleOTPSubmit} />}
						{ step === 'selectshop' && <ShopSelector /> }

						{ step !== 'login' && <Button 
							fullWidth
							size="large"
							sx={{ mt: 3 }}
							variant="text"
							onClick={() => { signOut(); setStep('login');  }}
						>
							Back to Login
						</Button> }
					</div>
				</Box>
			</Box>
		</>
	);
}

export default Login;