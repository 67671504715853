// import ChartBarIcon from '@heroicons/react/24/solid/ChartBarIcon';
import CogIcon from '@heroicons/react/24/solid/CogIcon';
import UsersIcon from '@heroicons/react/24/solid/UsersIcon';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import ChartBarIcon from '@mui/icons-material/';
import PeopleIcon from '@mui/icons-material/People';
import { SvgIcon } from '@mui/material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
export const items = [
	{
		title: 'Report',
		path: '/dashboard/report',
		icon: (
			<SvgIcon fontSize="small">
				<QueryStatsIcon />
			</SvgIcon>
		)
	},
	{
		title: 'Users',
		path: '/dashboard/users',
		icon: (
			<SvgIcon fontSize="small">
				<UsersIcon />
			</SvgIcon>
		)
	},
	{
		title: 'Customers',
		path: '/dashboard/customers',
		icon: (
			<SvgIcon fontSize="small">
				<PeopleIcon />
			</SvgIcon>
		)
	},
	{
		title: 'Products',
		path: '/dashboard/products',
		icon: (
			<SvgIcon fontSize="small">
				<Inventory2Icon />
			</SvgIcon>
		)
	},
	{
		title: 'Services',
		path: '/dashboard/services',
		icon: (
			<SvgIcon fontSize="small">
				<Inventory2Icon />
			</SvgIcon>
		)
	},
	{
		title: 'Orders',
		path: '/dashboard/orders',
		icon: (
			<SvgIcon fontSize="small">
				<ShoppingCartIcon />
			</SvgIcon>
		)
	},
	{
		title: 'Expense',
		path: '/dashboard/expenses',
		icon: (
			<SvgIcon fontSize="small">
				<CurrencyRupeeIcon />
			</SvgIcon>
		)
	},
	{
		title: 'Settings',
		path: '/dashboard/settings',
		icon: (
			<SvgIcon fontSize="small">
				<CogIcon />
			</SvgIcon>
		)
	}
];
