import InfoCircleIcon from '@mui/icons-material/InfoRounded';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Stack,
	SvgIcon,
	Tooltip,
	Typography,
	Unstable_Grid2 as Grid
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Chart from './chart';

interface ChartOptions {
	labels: string[];
}

const useChartOptions = (labels: ChartOptions) => {
	const theme = useTheme();

	return {
		chart: {
			background: 'transparent',
			stacked: false,
			toolbar: {
				show: false
			}
		},
		colors: [
			theme.palette.primary.main,
			theme.palette.warning.main,
			theme.palette.info.main
		],
		dataLabels: {
			enabled: false
		},
		fill: {
			opacity: 1,
			type: 'solid'
		},
		legend: {
			show: false
		},
		plotOptions: {
			pie: {
				expandOnClick: false
			}
		},
		states: {
			active: {
				filter: {
					type: 'none'
				}
			},
			hover: {
				filter: {
					type: 'none'
				}
			}
		},
		stroke: {
			width: 0
		},
		theme: {
			mode: theme.palette.mode
		},
		tooltip: {
			fillSeriesColor: false
		}
	};
};

interface ReportDonutProps {
	chartSeries: any;
	labels: any;
	title: string;
}

export const ReportDonut = (props: ReportDonutProps) => {
	const { chartSeries, labels, title } = props;
	const chartOptions = useChartOptions(labels);

	return (
		<Card>
			<CardHeader
				title={title}
				action={(
					<Tooltip title="Refresh rate is 24h">
						<SvgIcon color="action">
							<InfoCircleIcon />
						</SvgIcon>
					</Tooltip>
				)}
			/>
			<CardContent>
				<Chart
					height={200}
					options={chartOptions}
					series={chartSeries}
					type="donut"
				/>
				<Grid
					container
					spacing={1}
				>
					{chartSeries.map((item: any, index: number) => (
						<Grid
							key={index}
							xs={12}
							sm={6}
						>
							<Stack
								alignItems="center"
								direction="row"
								spacing={1}
							>
								<Box
									sx={{
										backgroundColor: chartOptions.colors[index],
										borderRadius: '50%',
										height: 8,
										width: 8
									}}
								/>
								<Typography variant="subtitle2">
									{labels[index]}
								</Typography>
							</Stack>
						</Grid>
					))}
				</Grid>
			</CardContent>
		</Card>
	);
};
