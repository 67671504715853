import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import GenericModal from "../generic/generic.modal";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useQuery } from "@apollo/client";
import { HairService } from "../../gql/graphql";
import { LIST_PRODUCTS } from "../../gql/queries";
import { useShopDetails } from "../../hooks/use-shop";

interface ProductSelectProps {
	onClose: (value?: any) => void;
}

export const ProductSelect = (props: ProductSelectProps) => {
	const { shopId, locationId } = useShopDetails();
	const { data } = useQuery(LIST_PRODUCTS, {
		variables: { shopId, locationId },
	});

	const hairproducts: any[] = React.useMemo(() => {
		return data?.hairproducts ? data?.hairproducts : [];
	}, [data]);

	const [selected, setSelected] = React.useState<HairService | null>(null);

	const handleChange = (event: React.SyntheticEvent, value: HairService) => {
		setSelected(value);
	};

	return (
		<GenericModal open={true} title={"Select Product"}>
			<DialogContent>
				<Box component="form" sx={{ height: 300 }}>
					<FormControl sx={{ m: 1, minWidth: 120 }}>
						<Autocomplete
							disablePortal
							id="service-select"
							getOptionLabel={(option) => `${option.name} (₹${option.price})`}
							options={hairproducts}
							sx={{ width: 500 }}
							onChange={handleChange}
							renderInput={(params) => (
								<TextField {...params} label="Product" />
							)}
						/>
					</FormControl>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setSelected(null);
						props?.onClose();
					}}
				>
					Cancel
				</Button>
				<Button
					onClick={() => {
						props?.onClose(selected);
					}}
				>
					Ok
				</Button>
			</DialogActions>
		</GenericModal>
	);
};
