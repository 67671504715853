import { useAuthContext } from '../contexts/auth-context';

export function useShopDetails(): { shopId: string, locationId: string } {
	let { shopId, locationId } = useAuthContext();

	if (!shopId) {
		shopId = localStorage.getItem('shopId')!;
	}

	if (!locationId) {
		locationId = localStorage.getItem('locationId')!;
	}

	return {
		shopId,
		locationId,
	};
}
  