import { useState, useMemo } from "react";
import {
	Box,
	Card,
	Stack,
	Table,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Grid,
	TableBody,
	MenuItem,
	SvgIcon,
	TextField,
} from "@mui/material";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";

import { AddButtonComponent } from "../button";
import { Scrollbar } from "../layouts/scrollbar";
import { ServiceSelect } from "./service.select";
import { ProductSelect } from "./product.select";
import { useOrderContext } from "../../contexts/order-context";
import {
	HairProduct,
	HairService,
	OrderProductInput,
	OrderServiceInput,
} from "../../gql/graphql";
import { SelectComponent } from "../select";
import { calculateOrderTotal } from "../../utils/order.utils";
import { useQuery } from "@apollo/client";
import { LIST_USERS } from "../../gql/queries";
import { useShopDetails } from "../../hooks/use-shop";
import { User } from "@sentry/react";

const columns = [
	{ title: "Item", key: "item" },
	{ title: "Quantity", key: "quantity" },
	{ title: "Price", key: "price" },
	{ title: "Total", key: "total" },
	{ title: "Disc Type", key: "discountType" },
	{ title: "Stylist", key: "stylistUserId" },
	{ title: "Disc Value", key: "discountValue" },
	{ title: "Delete", key: "action" },
];
export interface Column {
	key: string;
	title: string;
	formatter?: (column: Column, data: any) => React.ReactNode;
}

interface OrderTableProps {
	products?: OrderProductInput[] | undefined;
	services?: OrderServiceInput[] | undefined;
}

export const OrderTable = (props: OrderTableProps) => {
	const [modalType, setModalType] = useState<"service" | "product" | false>(
		false
	);

	const orderContext = useOrderContext();
	const { shopId, locationId } = useShopDetails();

	const { data } = useQuery(LIST_USERS, {  variables: { shopId, locationId } });

	const usersSelectMap: any[] = useMemo(
		() => {
			return data?.users?.map((user: User)=>({ value: user._id, label: `${user.firstName} ${user.lastName}` })) ?? [];
		},
		[data]
	);

	const Modal = useMemo(() => {
		if (modalType === "service") {
			return (
				<ServiceSelect
					onClose={(value: HairService) => {
						if (value) {
							const orderServiceInput: OrderServiceInput = {
								serviceId: value._id!,
								name: value.name,
								price: value.price,
								quantity: 1,
								total: value.price,
							};
							orderContext.addService(orderServiceInput);
						}
						setModalType(false);
					}}
				/>
			);
		}
		if (modalType === "product") {
			return (
				<ProductSelect
					onClose={(value: HairProduct) => {
						if (value) {
							const orderProductInput: OrderProductInput = {
								productId: value._id!,
								name: value.name,
								price: value.price,
								quantity: 1,
								total: value.price,
							};
							orderContext.addProduct(orderProductInput);
						}
						setModalType(false);
					}}
				/>
			);
		}
		return null;
	}, [modalType]);

	return (
		<Stack spacing={3}>
			<Stack direction="row" justifyContent="space-between" spacing={4}>
				<Stack spacing={1}>
					<Typography variant="h4">Items</Typography>
				</Stack>
				<Grid
					item
					xs={8}
					columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					justifyContent="flex-end"
				>
					<AddButtonComponent
						onClick={() => {
							setModalType("service");
						}}
						label="Service"
					/>
					<AddButtonComponent
						onClick={() => {
							setModalType("product");
						}}
						label="Product"
					/>
				</Grid>
			</Stack>
			<Stack justifyContent="center">
				<Card>
					<Scrollbar>
						<Box sx={{ minWidth: 800 }}>
							<Table>
								<TableHead>
									<TableRow>
										{columns.map((column: Column) => (
											<TableCell key={column.key}>{column.title}</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{orderContext?.order?.products?.map(
										(product: OrderProductInput) => {
											return (
												<TableRow hover key={product.productId}>
													<TableCell> {product?.name} </TableCell>
													<TableCell>
														<TextField
															value={product?.quantity!}
															onChange={(event) => {
																orderContext.updateProductQuantity(
																	product,
																	Number(event?.target?.value)
																);
															}}
															sx={{ width: "50px" }}
														/>
													</TableCell>
													<TableCell> &#8377; {product?.price}</TableCell>
													<TableCell>
														{" "}
														&#8377;
														{product?.total! - (product?.discountAmount! || 0)}
													</TableCell>
													<TableCell>
														<SelectComponent
															selected={product?.discountType! || ""}
															data={[
																{ label: "Percentage", value: "PERCENTAGE" },
																{ label: "Amount", value: "AMOUNT" },
															]}
															label="Discount Type"
															onChange={(value: string) => {
																orderContext.updateProductDiscountType(
																	product,
																	value
																);
															}}
														/>
													</TableCell>
													<TableCell>
														<SelectComponent
															selected={product?.stylistUserId! || ""}
															data={usersSelectMap}
															label="Stylist User"
															onChange={(value: string) => {
																orderContext.updateStylist(
																	product,
																	value,
																	"PRODUCT"
																);
															}}
														/>
													</TableCell>
													<TableCell>
														<TextField
															value={product?.discountValue}
															onChange={(event) => {
																orderContext.updateProductDiscount(
																	product,
																	Number(event?.target?.value || 0)
																);
															}}
															sx={{ width: "50px" }}
														/>
													</TableCell>
													<TableCell>
														<MenuItem
															sx={{ color: "error.main" }}
															onClick={() => {
																orderContext.removeProduct(product);
															}}
														>
															<SvgIcon color="action" fontSize="small">
																<TrashIcon />
															</SvgIcon>
														</MenuItem>
													</TableCell>
												</TableRow>
											);
										}
									)}
									{orderContext?.order?.services?.map(
										(service: OrderServiceInput) => {
											return (
												<TableRow hover key={service.serviceId}>
													<TableCell> {service?.name} </TableCell>
													<TableCell>
														<TextField
															value={service?.quantity!}
															onChange={(event) => {
																orderContext.updateServiceQuantity(
																	service,
																	Number(event?.target?.value)
																);
															}}
															sx={{ width: "50px" }}
														/>
													</TableCell>
													<TableCell> &#8377;{service?.price}</TableCell>
													<TableCell>
														{" "}
														&#8377;{" "}
														{service?.total! - (service?.discountAmount! || 0)}
													</TableCell>
													<TableCell>
														<SelectComponent
															selected={service?.discountType! || ""}
															data={[
																{ label: "Percentage", value: "PERCENTAGE" },
																{ label: "Amount", value: "AMOUNT" },
															]}
															label="Discount Type"
															onChange={(value: string) => {
																orderContext.updateServiceDiscountType(
																	service,
																	value
																);
															}}
														/>
													</TableCell>
													<TableCell>
														<SelectComponent
															selected={service?.stylistUserId! || ""}
															data={usersSelectMap}
															label="Stylist User"
															onChange={(value: string) => {
																orderContext.updateStylist(
																	service,
																	value,
																	"SERVICE"
																);
															}}
														/>
													</TableCell>
													<TableCell>
														<TextField
															value={service?.discountValue}
															onChange={(event) => {
																orderContext.updateServiceDiscount(
																	service,
																	Number(event?.target?.value || 0)
																);
															}}
															sx={{ width: "50px" }}
														/>
													</TableCell>
													<TableCell>
														<MenuItem
															sx={{ color: "error.main" }}
															onClick={() => {
																orderContext.removeService(service);
															}}
														>
															<SvgIcon color="action" fontSize="small">
																<TrashIcon />
															</SvgIcon>
														</MenuItem>
													</TableCell>
												</TableRow>
											);
										}
									)}
									{
										calculateOrderTotal(orderContext?.order!).map((item) => {
											return (
												<TableRow>
													<TableCell colSpan={5} sx={{ borderBottom: "none" }} />
													<TableCell sx={{ borderBottom: "none" }}>
														<Typography variant="subtitle1">{item.label}</Typography>
													</TableCell>
													<TableCell align="right" sx={{ borderBottom: "none" }}>
														<Typography variant="subtitle2">&#8377;{item.value}</Typography>
													</TableCell>
												</TableRow>
											)
										})
									}
								</TableBody>
							</Table>
						</Box>
					</Scrollbar>
				</Card>
			</Stack>
			{Modal}
		</Stack>
	);
};
