import { useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Box, Container } from '@mui/material';

import { useSelection } from '../../hooks/use-selection';
import { GenericTable, Column } from '../../components/generic/generic.table';

import { useMutation, useQuery } from '@apollo/client';
import { DATE_FORMAT } from '../../constants';
import EditExpense from '../../components/generic/edit.expense';
import { Expense } from '../../gql/graphql';
import { DETELE_EXPENSE, LIST_EXPENSES } from '../../gql/queries';
import { useShopDetails } from '../../hooks/use-shop';
import { toast } from 'react-toastify';

const columns: Column[] = [
	{ title: 'Name', key: 'name' },
	{ title: 'Price', key: 'price', formatter: (column: Column, data: any) => { return `₹ ${data['price']}` } },
	{ title: 'Created', key: 'createdAt', formatter: (column: Column, data: any) => { return format(new Date(data[column.key]), DATE_FORMAT); } },
];

const useCustomerIds = (customers: any) => {
	return useMemo(
		() => {
			return customers.map((customer: any) => customer.id);
		},
		[customers]
	);
};
function ExpenseList() {

	const { shopId, locationId } = useShopDetails();
	const { data, refetch } = useQuery(LIST_EXPENSES, { variables: { shopId, locationId } });

	const expenseList: Expense[] = useMemo(
		() => {
			return data?.expenses ? data?.expenses : []
		},
		[data]
	);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	// const customers = useCustomers(page, rowsPerPage, data?.listCustomer);
	const customersIds = useCustomerIds(expenseList);
	const customersSelection = useSelection(customersIds);

	const handlePageChange = useCallback(
		(event: any, value: any) => {
			setPage(value);
		},
		[]
	);

	const handleRowsPerPageChange = useCallback(
		(event: any) => {
			setRowsPerPage(event.target.value);
		},
		[]
	);

	const [deleteExpense] = useMutation(DETELE_EXPENSE);
	const handleDeleteOne = useCallback(
		async (expense: Expense) => {
			await deleteExpense({ variables: { expenseId: expense._id } });
			toast.success(`Deleted ${expense.name} successfully.`);
			await refetch();
		},
		// eslint-disable-next-line
		[]
	);


	return (
		<>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8
				}}
			>
				<Container maxWidth="xl">
					<GenericTable
						title='Expenses'
						data={expenseList}
						columns={columns}
						onDeselectAll={customersSelection.handleDeselectAll}
						onDeselectOne={customersSelection.handleDeselectOne}
						onPageChange={handlePageChange}
						onRowsPerPageChange={handleRowsPerPageChange}
						onSelectAll={customersSelection.handleSelectAll}
						onSelectOne={customersSelection.handleSelectOne}
						onDeleteOne={handleDeleteOne}
						page={page}
						rowsPerPage={rowsPerPage}
						selected={customersSelection.selected}
						addEditComponent={EditExpense}
						refetch={refetch}
					/>
				</Container>
			</Box>
		</>
	);
}

export default ExpenseList;
