import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SxProps, Theme } from '@mui/material';

interface SelectComponentProps {
	data: any;
	label: string;
	selected?: string;
	sx?: SxProps<Theme>;
	onChange?: (value: any) => void;
}

export const SelectComponent = (props: SelectComponentProps) => {
	const [selected, setSelected] = React.useState(props.selected || '');
	React.useEffect(() => {
		setSelected(props.selected || '');
	}, [props.selected]);
	const handleChange = (event: SelectChangeEvent) => {
		setSelected(event.target.value as string);
		props.onChange && props.onChange(event.target.value as string);
	};

	return (
		<Box sx={{ minWidth: 120, ...props.sx }}>
			<FormControl fullWidth>
				<InputLabel id={props.label}>{props.label}</InputLabel>
				<Select
					labelId={props.label}
					id={props.label}
					value={selected}
					label={props.label}
					onChange={handleChange}
				>
					{
						props.data.map((item: any) => {
							return <MenuItem value={item.value} key={item.label} >{item.label}</MenuItem>
						})
					}
				</Select>
			</FormControl>
		</Box>
	);
}