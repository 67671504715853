import PropTypes from 'prop-types';
import {
	Box,
	Divider,
	Drawer,
	Stack,
	Typography,
} from '@mui/material';
import { Scrollbar } from './scrollbar';
import { items } from '../../config';
import { SideNavItem } from './side-nav-item';
import { useAuthContext } from '../../contexts/auth-context';

export const SideNav = (props: any) => {
	const { open, onClose } = props;
	const lgUp = true;
	const { shopDetails, locationName } = useAuthContext();

	const content = (
		<Scrollbar
			sx={{
				height: '100%',
				'& .simplebar-content': {
					height: '100%'
				},
				'& .simplebar-scrollbar:before': {
					background: 'neutral.400'
				}
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%'
				}}
			>
				<Box sx={{ p: 3 }}>
					<Box
						sx={{
							display: 'inline-flex',
							height: 32,
							width: 32
						}}
					>
					</Box>
					<Box
						sx={{
							alignItems: 'center',
							backgroundColor: 'rgba(255, 255, 255, 0.04)',
							borderRadius: 1,
							cursor: 'pointer',
							display: 'flex',
							justifyContent: 'space-between',
							mt: 2,
							p: '12px'
						}}
					>
						<div>
							<Typography
								color="inherit"
								variant="subtitle1"
							>
								{shopDetails?.name} ( {locationName} )
							</Typography>
						</div>
					</Box>
				</Box>
				<Divider sx={{ borderColor: 'neutral.700' }} />
				<Box
					component="nav"
					sx={{
						flexGrow: 1,
						px: 2,
						py: 3
					}}
				>
					<Stack
						component="ul"
						spacing={0.5}
						sx={{
							listStyle: 'none',
							p: 0,
							m: 0
						}}
					>
						{items.map((item) => {

							return (
								<SideNavItem
									active={false}
									disabled={false}
									external={false}
									icon={item.icon}
									key={item.title}
									path={item.path}
									title={item.title}
								/>
							);
						})}
					</Stack>
				</Box>

			</Box>
		</Scrollbar>
	);

	if (lgUp) {
		return (
			<Drawer
				anchor="left"
				open
				PaperProps={{
					sx: {
						backgroundColor: 'neutral.800',
						color: 'common.white',
						width: 280
					}
				}}
				variant="permanent"
			>
				{content}
			</Drawer>
		);
	}

	return (
		<Drawer
			anchor="left"
			onClose={onClose}
			open={open}
			PaperProps={{
				sx: {
					backgroundColor: 'neutral.800',
					color: 'common.white',
					width: 280
				}
			}}
			sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
			variant="temporary"
		>
			{content}
		</Drawer>
	);
};

SideNav.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool
};
