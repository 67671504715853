import { Navigate, useRoutes } from 'react-router-dom';

import LoginPage from './pages/login.page';
import { Layout as DashboardLayout } from './components/layouts/dashboard.layout';
import OverviewPage from './pages/dashboard/overview.page';
import UserListPage from './pages/dashboard/user-list.page';
import ProductListPage from './pages/dashboard/product-list.page';
import ServiceListPage from './pages/dashboard/service-list.page';
import CustomerListPage from './pages/dashboard/customer-list.page';
import OrderListPage from './pages/dashboard/order-list.page';
import SettingsPage from './pages/dashboard/settings.page';
import ExpenseList from './pages/dashboard/expense-list.page';
import ReportPage from './pages/dashboard/report.page';

export default function Router() {
	const routes = useRoutes([
		{
			path: '/dashboard',
			element: <DashboardLayout />,
			children: [
				{ element: <Navigate to="/dashboard/users" />, index: true },
				{ path: 'overview', element: <OverviewPage /> },
				{ path: 'report', element: <ReportPage /> },
				{ path: 'users', element: <UserListPage /> },
				{ path: 'products', element: <ProductListPage /> },
				{ path: 'services', element: <ServiceListPage /> },
				{ path: 'customers', element: <CustomerListPage /> },
				{ path: 'orders', element: <OrderListPage /> },
				{ path: 'expenses', element: <ExpenseList /> },
				{ path: 'settings', element: <SettingsPage /> },

			],
		},
		{
			path: 'login',
			element: <LoginPage />,
		},
		{
			path: '/',
			element: <LoginPage />,
		},
		{
			path: '/404',
			element: <p>Page not found.</p>,
		},
		{
			path: '*',
			element: <Navigate to="/404" replace />,
		},
	]);

	return routes;
}
