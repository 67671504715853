import { useState, useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";

import { ReportSearch } from "../components/report/search";


interface UserSearchResult {
	SearchComponent: JSX.Element;
	searchQuery: SearchQuery;
}

interface SearchQuery {
	from: Date;
	to: Date;
	userId: string | undefined;
}

export const useSearch = (): UserSearchResult => {
	// TODO: refactor this repeated code
	const [from, setFrom] = useState<Dayjs>(dayjs(new Date()).startOf('day'));
	const [to, setTo] = useState<Dayjs>(dayjs(new Date()).endOf("day"));
	const [selectedUser, setSelectedUser] = useState<string | undefined>();
	// TODO: refactor this repeated code

	const handleOnSearch = (from: Dayjs, to: Dayjs, userId: string | undefined) => {
		setFrom(from);
		setTo(to);
		setSelectedUser(userId);
	};

	const SearchComponent = useMemo(() => {
		return <ReportSearch onSearch={handleOnSearch} />;
	}, []);

	return { SearchComponent, searchQuery: { from: from!.toDate(), to: to!.toDate(), userId: selectedUser } };
}