import { TableCell, TableRow } from "@mui/material";

interface NoDataRowProps {
	colSpan: number;
}

export const NodataRow = ({colSpan}: NoDataRowProps) => {
	return (
		<TableRow>
			<TableCell colSpan={colSpan} align='center'>
				No Data Found
			</TableCell>
		</TableRow>
	);
};