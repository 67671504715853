
import { useCallback } from 'react';
import { format } from 'date-fns';
import { Box, Container } from '@mui/material';

import { GenericTable, Column } from '../../components/generic/generic.table';

import { useMutation, useQuery } from '@apollo/client';
import { DATE_FORMAT } from '../../constants';
import EditOrderComponent from '../../components/generic/edit.order';
import { LIST_ORDERS, DELETE_ORDER } from '../../gql/queries';
import { useShopDetails } from '../../hooks/use-shop';
import { toast } from 'react-toastify';
import { Order } from '../../gql/graphql';
import { useSearch } from '../../hooks/use-search';


const columns: Column[] = [
	{ title: 'Order Id', key: 'sequenceNumber' },
	{ title: 'Customer', key: 'customer', formatter: (column: Column, data: any) => { return data['customerName'] } },
	{ title: 'Total', key: 'total', formatter: (column: Column, data: any) => { return `₹ ${data['total']}` } },
	{ title: 'Payment Method', key: 'paymentMethod' },
	{ title: 'Created', key: 'createdAt', formatter: (column: Column, data: any) => { return format(new Date(data[column.key]), DATE_FORMAT); } },
];


function OrderList() {
	const { shopId, locationId } = useShopDetails();

	const { SearchComponent, searchQuery } = useSearch();

	const { data, refetch } = useQuery(LIST_ORDERS, { variables: { query: {  shopId, locationId, startDate: searchQuery.from, endDate: searchQuery.to, stylistUserId: searchQuery.userId }  } });

	const [deleteOrder] = useMutation(DELETE_ORDER);

	const handleDeleteOne = useCallback(
		async (order: Order) => {
			try {
				await deleteOrder({ variables: { orderId: order._id } });
				toast.success(`Deleted ${order.sequenceNumber} successfully.`);
				await refetch();
			} catch (e: any) {
				toast.error(e.message);
			}
		},
		[]
	);
	return (
		<>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8
				}}
			>
				<Container maxWidth="xl">
					<GenericTable
						title='Orders'
						data={data?.orders ? data?.orders : []}
						columns={columns}
						refetch={refetch}
						addEditComponent={EditOrderComponent}
						onDeleteOne={handleDeleteOne}
						SearchComponent={SearchComponent}
					/>
				</Container>
			</Box>
		</>
	);
}

export default OrderList;
