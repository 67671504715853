import { useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Box, Container } from '@mui/material';

import { useSelection } from '../../hooks/use-selection';
import { GenericTable, Column } from '../../components/generic/generic.table';

import { useMutation, useQuery } from '@apollo/client';
import { DATE_FORMAT } from '../../constants';
import EditHairProduct from '../../components/generic/edit.hairproduct';
import { HairProduct } from '../../gql/graphql';
import { DETELE_PRODUCT, LIST_PRODUCTS } from '../../gql/queries';
import { useShopDetails } from '../../hooks/use-shop';
import { toast } from 'react-toastify';

const columns: Column[] = [
	{ title: 'Name', key: 'name' },
	{ title: 'Quantity', key: 'quantity' },
	{ title: 'Price', key: 'price' },
	{ title: 'Created', key: 'createdAt', formatter: (column: Column, data: any) => { return format(new Date(data[column.key]), DATE_FORMAT); } },
];

const useCustomerIds = (customers: any) => {
	return useMemo(
		() => {
			return customers.map((customer: any) => customer.id);
		},
		[customers]
	);
};
function HairProductList() {

	const { shopId, locationId } = useShopDetails();
	const { data, refetch } = useQuery(LIST_PRODUCTS, { variables: { shopId, locationId } });

	const hairproducts: HairProduct[] = useMemo(
		() => {
			return data?.hairproducts ? data?.hairproducts : []
		},
		[data]
	);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	// const customers = useCustomers(page, rowsPerPage, data?.listCustomer);
	const customersIds = useCustomerIds(hairproducts);
	const customersSelection = useSelection(customersIds);

	const handlePageChange = useCallback(
		(event: any, value: any) => {
			setPage(value);
		},
		[]
	);

	const handleRowsPerPageChange = useCallback(
		(event: any) => {
			setRowsPerPage(event.target.value);
		},
		[]
	);

	const [deleteProduct] = useMutation(DETELE_PRODUCT);
	const handleDeleteOne = useCallback(
		async (product: HairProduct) => {
			await deleteProduct({ variables: { productId: product._id } });
			toast.success(`Deleted ${product.name} successfully.`);
			await refetch();
		},
		// eslint-disable-next-line
		[]
	);


	return (
		<>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8
				}}
			>
				<Container maxWidth="xl">
					<GenericTable
						title='Products'
						data={hairproducts}
						columns={columns}
						onDeselectAll={customersSelection.handleDeselectAll}
						onDeselectOne={customersSelection.handleDeselectOne}
						onPageChange={handlePageChange}
						onRowsPerPageChange={handleRowsPerPageChange}
						onSelectAll={customersSelection.handleSelectAll}
						onSelectOne={customersSelection.handleSelectOne}
						onDeleteOne={handleDeleteOne}
						page={page}
						rowsPerPage={rowsPerPage}
						selected={customersSelection.selected}
						addEditComponent={EditHairProduct}
						refetch={refetch}
					/>
				</Container>
			</Box>
		</>
	);
}

export default HairProductList;
