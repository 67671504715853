import { PaperSize, PosPrintData, PosPrintOptions, SizeOptions } from "@3ksy/electron-pos-printer";
import {
	Location,
	OrderInput,
	OrderProduct,
	OrderService,
	Shop,
} from "../gql/graphql";
import { getDate, getTime } from "./date.util";
import { calculateOrderTotal } from "./order.utils";

const getAddress = (location: Location): string => {
	const { city, landmark } = location.address;
	// return `${street ?? ""}, ${landmark ?? ""}, ${city ?? ""}, ${
	//   state ?? ""
	// } - ${zipcode}`;
	return `${landmark ?? ""}, ${city ?? ""}`;
};

const getInvoiceRow = (value: string): PosPrintData => {
	return {
		type: "text",
		value,
		style: { textAlign: "left", fontSize: "15px" },
	};
};


export const printPOSReceipt = async (orderData: OrderInput, shop: Shop) => {
	if (window?.electronAPI?.isDesktop) {
		const margin = localStorage.getItem("printer-margin") || "0 0 0 0";
		const preview = localStorage.getItem("preview") ? true : false;
		const pageSize = (localStorage.getItem("page-size") || "80mm") as PaperSize | SizeOptions;
		const options: PosPrintOptions = {
			copies: 1,
			boolean: true,
			preview,
			margin,
			pageSize,
			printerName: localStorage.getItem("printer-name") || "Default",
		};
		const productsTable = orderData.products.map((item: OrderProduct) => {
			return [
				item.name,
				`${item.price}`,
				`${item.quantity}`,
				`${item.discountAmount ?? 0}`,
				`${(item.price * item.quantity)-(item?.discountAmount ?? 0)}`,
			];
		});
		const servicesTable = orderData.services.map((item: OrderService) => {
			return [
				item.name,
				`${item.price}`,
				`${item.quantity}`,
				`${item.discountAmount ?? 0}`,
				`${(item.price * item.quantity)-(item?.discountAmount ?? 0)}`,
			];
		});

		let footers: PosPrintData[] = [];
		calculateOrderTotal(orderData).forEach((item) => {
			footers.push(getInvoiceRow(`${item.label}     : ${item.value}`));
		});
		const data: PosPrintData[] = [
			{
				type: "text",
				position: "center",
				style: { textAlign: "center", fontSize: "24px" },
				value: shop.name,
			},
			{
				type: "text",
				value: getAddress(shop?.location[0]!),
				style: {
					textAlign: "center",
					fontSize: "12px",
					marginRight: "10px",
					marginLeft: "10px",
				},
				position: "center",
			},
			{
				type: "text",
				value: `Mobile: ${shop.location[0].phone}`,
				style: { textAlign: "center" },
				position: "center",
			},
			{
				type: "text",
				value: "Invoice",
				style: {
					textAlign: "center",
					marginTop: "10px",
					fontStyle: "bold",
					fontSize: "16px",
				},
				position: "center",
			},
			{
				type: "text",
				style: { textAlign: "center", fontSize: "12px" },
				value: "=======================================",
			},
			getInvoiceRow(`Order No     : ${orderData.sequenceNumber}`),
			// Date fns format date
			getInvoiceRow(`Order Date   : ${getDate(orderData.createdAt)}`),
			getInvoiceRow(`Order Time   : ${getTime(orderData.createdAt)}`),
			{
				type: "text",
				style: { textAlign: "center", fontSize: "12px" },
				value: "=======================================",
			},
			{
				type: "table",
				tableHeader: ["Item", "Price", "Qty", "Disc","Total"],
				tableBody: [
					...productsTable,
					...servicesTable
				],
				// custom style for the table header
				tableHeaderStyle: { backgroundColor: "white", color: "black" },
				// custom style for the table body
				tableBodyStyle: { border: "0.5px solid #ddd" },
				// custom style for the table footer
				tableFooterStyle: { backgroundColor: "#000", color: "white" },
			},
			{
				type: "text",
				style: { textAlign: "center", fontSize: "12px" },
				value: "=======================================",
			},
			...footers
		];
		return await window?.electronAPI?.printPOS(data, options);
	}
};
