import { useFormik } from 'formik';
import * as yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	Unstable_Grid2 as Grid
} from '@mui/material';
import { toast } from 'react-toastify';

import { useLazyQuery, useMutation } from '@apollo/client';
import GenericModal from './generic.modal';
import { useEffect } from 'react';
import { User, UserType } from '../../gql/graphql';
import { useShopDetails } from '../../hooks/use-shop';
import { ADD_UPDATE_USER, GET_USER_BY_ID } from '../../gql/queries';




const validationSchema = yup.object({
	firstName: yup
		.string()
		.required('First name is required'),
	email: yup
		.string()
		.email('Enter a valid email')
		.required('Email is required'),
	phone: yup
		.string()
		.min(10, 'Phone number is too short')
		.max(10, 'Phone number is too long')
		.required('Phone number is required'),
});

function EditUser(props: any) {
	const { data: user, onClose } = props;
	const { shopId, locationId } = useShopDetails();
	const [getUserById] = useLazyQuery<Record<'user', User>>(GET_USER_BY_ID, {});
	const [addUpdateUser, { loading }] = useMutation(ADD_UPDATE_USER);

	const formik = useFormik<Partial<User>>({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			shopId,
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				const { data } = await addUpdateUser({ variables: { user: { ...values, roles: [{ locationId, role: UserType.LocationUser }] } } });
				const { firstName, lastName } = data.addUpdateUser;
				toast.success(`User: ${firstName} ${lastName} ${user?._id ? "updated" : "created"} successfully.`);
				props.onClose(data.addUpdateUser);
			} catch (e: any) {
				toast.error(e.message);
			}
		},
	});

	useEffect(() => {
		if (user?._id) {
			const setInitialValues = async () => {
				const { data } = await getUserById({ variables: { id: user?._id, shopId, locationId } });
				if (data?.user) {
					formik.setValues({
						...formik.values,
						...data.user
					});
				}
			}
			setInitialValues();
		}
		// eslint-disable-next-line
	}, []);

	return (
		<GenericModal open={true} title={`${user?._id ? "Edit" : "Add"} User`} close={props.onClose}>
			<form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
				<Card>
					<CardHeader
						subheader="The information can be edited"
					/>
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid
								container
								spacing={3}
							>
								<Grid
									xs={12}
									md={6}
								>
									<TextField
										fullWidth
										id="firstName"
										label="First name"
										name="firstName"
										value={formik.values.firstName}
										onChange={formik.handleChange}
										error={formik.touched.firstName && Boolean(formik.errors.firstName)}
										helperText={formik.touched.firstName && formik.errors.firstName}
									/>
								</Grid>
								<Grid
									xs={12}
									md={6}
								>
									<TextField
										fullWidth
										id="lastName"
										label="Last name"
										name="lastName"
										value={formik.values.lastName}
										onChange={formik.handleChange}
										error={formik.touched.lastName && Boolean(formik.errors.lastName)}
										helperText={formik.touched.lastName && formik.errors.lastName}
									/>
								</Grid>
								<Grid
									xs={12}
									md={6}
								>
									<TextField
										fullWidth
										id="email"
										label="Email"
										name="email"
										value={formik.values.email}
										onChange={formik.handleChange}
										error={formik.touched.email && Boolean(formik.errors.email)}
										helperText={formik.touched.email && formik.errors.email}
									/>
								</Grid>
								<Grid
									xs={12}
									md={6}
								>
									<TextField
										fullWidth
										id="phone"
										label="Phone"
										name="phone"
										value={formik.values.phone}
										onChange={formik.handleChange}
										error={formik.touched.phone && Boolean(formik.errors.phone)}
										helperText={formik.touched.phone && formik.errors.phone}
									/>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />
					<CardActions sx={{ justifyContent: 'flex-end' }}>
						<Button variant="outlined" disabled={loading} onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton loading={loading} variant="contained" type="submit" >
							Save
						</LoadingButton>
					</CardActions>
				</Card>
			</form>
		</GenericModal>
	);
}

export default EditUser;
