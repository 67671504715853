import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, Stack, SvgIcon } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { SelectComponent } from "../select";
import { useShopDetails } from "../../hooks/use-shop";
import { LIST_USERS } from "../../gql/queries";
import { useQuery } from "@apollo/client";
import { User } from "../../gql/graphql";

// const platformOptions = ['Web', 'Node.js', 'Python', 'C#'];

interface ReportSearchProps {
	onSearch: (from: Dayjs, to: Dayjs, stylistUserId: string | undefined) => void;
}
export const ReportSearch = (props: ReportSearchProps) => {
	const [from, setFrom] = useState<Dayjs>(dayjs(new Date()).startOf("day"));
	const [to, setTo] = useState<Dayjs>(dayjs(new Date()).endOf("day"));
	const [selectedUser, setSelectedUser] = useState<string | undefined>();


	const { shopId, locationId } = useShopDetails();
	const { data } = useQuery(LIST_USERS, { variables: { shopId, locationId } });

	const usersSelectMap: any[] = useMemo(
		() => {
			const allUsers: any[] = [{ value: undefined, label: "All" }, ];
			return allUsers.concat(
				data?.users?.map((user: User) => ({ value: user._id, label: `${user.firstName} ${user.lastName}` })) ?? []
			);
		},
		[data]
	);

	useEffect(() => {
		props.onSearch(from, to, selectedUser);
	}, []);

	const handleOnClick = () => {
		if (from! > to!) {
			toast.error("From date cannot be greater than To date");
			return;
		}
		props.onSearch(from, to, selectedUser);
	};

	return (
		<Card>
			<Stack
				alignItems="center"
				direction="row"
				flexWrap="wrap"
				gap={3}
				sx={{ p: 3 }}
			>
				<div>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label="From"
							value={from}
							onChange={(newValue) =>
								setFrom((newValue as Dayjs).startOf("day"))
							}
						/>
					</LocalizationProvider>
				</div>
				<div>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label="To"
							value={to}
							onChange={(newValue) => 
								setTo((newValue as Dayjs).endOf("day"))
							}
						/>
					</LocalizationProvider>
				</div>
				<div>
					<SelectComponent
						sx={{
							p: "2px 4px",
							display: "flex",
							alignItems: "center",
							width: 300,
						}}
						data={usersSelectMap}
						selected={selectedUser}
						label="Stylist"
						onChange={(value: string) => {
							setSelectedUser(value);
						}}
					/>
				</div>
				<Button
					size="large"
					startIcon={
						<SvgIcon>
							<SearchIcon />
						</SvgIcon>
					}
					variant="contained"
					onClick={handleOnClick}
				>
					Search
				</Button>
			</Stack>
		</Card>
	);
};
