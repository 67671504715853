import { useCallback } from 'react';
import { format } from 'date-fns';
import { Box, Container } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { useSelection } from '../../hooks/use-selection';
import { GenericTable, Column } from '../../components/generic/generic.table';

import { useQuery, useMutation } from '@apollo/client';
import { DATE_FORMAT } from '../../constants';
import EditCustomer from '../../components/generic/edit.customer';
import { Customer } from '../../gql/graphql';
import { toast } from 'react-toastify';
import { DETELE_CUSTOMER, LIST_CUSTOMERS } from '../../gql/queries';
import { useShopDetails } from '../../hooks/use-shop';


const columns: Column[] = [
	{ title: 'First Name', key: 'firstName' },
	{ title: 'Last Name', key: 'lastName' },
	{ title: 'Phone', key: 'phone' },
	{ title: 'Email', key: 'email' },
	{ title: 'Created', key: 'createdAt', formatter: (column: Column, data: any) => { return format(new Date(data[column.key]), DATE_FORMAT); } },
];


function CustomerList(props: any) {
	const { shopId, locationId } = useShopDetails()
	const { data, refetch } = useQuery(LIST_CUSTOMERS, { variables: { shopId, locationId } });
	const [deleteCustomer] = useMutation(DETELE_CUSTOMER);

	const customersSelection = useSelection(data?.customers);

	const handleDeleteOne = useCallback(
		async (customer: Customer) => {
			await deleteCustomer({ variables: { customerId: customer._id, shopId } });
			toast.success(`Deleted ${customer.firstName} ${customer.lastName} successfully.`);
			await refetch();
		},
		// eslint-disable-next-line
		[]
	);

	return (
		<>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8
				}}
			>
				<Container maxWidth="xl">
					<GenericTable
						title='Customers'
						data={data?.customers ? data?.customers : []}
						columns={columns}
						onDeselectAll={customersSelection.handleDeselectAll}
						onDeselectOne={customersSelection.handleDeselectOne}
						onSelectAll={customersSelection.handleSelectAll}
						onSelectOne={customersSelection.handleSelectOne}
						onDeleteOne={handleDeleteOne}
						selected={customersSelection.selected}
						addEditComponent={EditCustomer}
						showSelectCheckBox={props.isModal}
						refetch={refetch}
					/>
					{props.isModal && <CardActions sx={{ justifyContent: 'flex-end' }} >
						<Button variant="outlined" onClick={() => { props.onClose() }} >
							Cancel
						</Button>
						<LoadingButton disabled={!customersSelection.selected} variant="contained" onClick={() => { props.onClose(customersSelection.selected) }}>
							Attach Selected
						</LoadingButton>
					</CardActions>}
				</Container>
			</Box>
		</>
	);
}

export default CustomerList;
