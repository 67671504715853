import { styled } from '@mui/material/styles';
import ApexChart from 'react-apexcharts';

const Chart = styled(ApexChart)(({ theme }) => ({
	'& .apexcharts-xaxistooltip': {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[16],
		borderRadius: theme.shape.borderRadius,
		border: 0,
		'&::before, &::after': {
			display: 'none'
		}
	},
	'& .apexcharts-tooltip': {
		'&.apexcharts-theme-light, &.apexcharts-theme-dark': {
			backdropFilter: 'blur(6px)',
			background: 'transparent',
			border: 0,
			boxShadow: 'none',
			'& .apexcharts-tooltip-title': {
				border: 0,
				color: theme.palette.common.white,
				margin: 0
			},
			'& .apexcharts-tooltip-series-group': {
				border: 0,
				color: theme.palette.common.white
			}
		}
	}
}));

export default Chart;
