import {
	Box,
	Divider,
	MenuItem,
	MenuList,
	Popover,
	Typography,
	ListItemButton,
	ListItemIcon,
	SvgIcon,
	ListItemText
} from "@mui/material";
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import { useAuthContext } from "../contexts/auth-context";
import { useNavigate } from "react-router-dom";

export const AccountPopover = (props: any) => {
	const { anchorEl, onClose, open } = props;
	const { jwtDecoded, signOut, setShop } = useAuthContext();
	const navigate = useNavigate();

	const resetShop = () => {
		setShop({
			shopId: '',
			locationId: '',
			locationName: '',
			shopName: ''
		});
		navigate('/login');
	}


	return (
		<Popover
			anchorEl={anchorEl}
			anchorOrigin={{
				horizontal: "left",
				vertical: "bottom",
			}}
			onClose={onClose}
			open={open}
			PaperProps={{ sx: { width: 200 } }}
		>
			<Box
				sx={{
					py: 1.5,
					px: 2,
				}}
			>
				<Typography variant="overline">Account</Typography>
				<Typography color="text.secondary" variant="body2">
					{jwtDecoded?.email}
				</Typography>
			</Box>
			<Divider />
			<Box sx={{ p: 1 }}>
				<ListItemButton
					onClick={resetShop}
					sx={{
						borderRadius: 1,
						px: 1,
						py: 0.5,
					}}
				>
					<ListItemIcon>
						<SvgIcon fontSize="small">
							<SwitchAccessShortcutIcon />
						</SvgIcon>
					</ListItemIcon>
					<ListItemText
						primary={<Typography variant="body1">Switch Shop</Typography>}
					/>
				</ListItemButton>
			</Box>
			<Divider />
			<MenuList
				disablePadding
				dense
				sx={{
					p: "8px",
					"& > *": {
						borderRadius: 1,
					},
				}}
			>
				<MenuItem onClick={signOut}>Sign out</MenuItem>
			</MenuList>
		</Popover>
	);
};
