import { useCallback, useState } from "react";
import {
	Box,
	Container,
	Divider,
	Stack,
	Tab,
	Tabs,
	Typography,
} from "@mui/material";
import { PrinterSettings } from "../../components/settings/printer.settings";

const tabs = [
	{ label: "General", value: "general" },
	{ label: "Billing", value: "billing" },
	{ label: "Team", value: "team" },
	{ label: "Notifications", value: "notifications" },
	{ label: "Security", value: "security" },
];

const Page = () => {
	const [currentTab, setCurrentTab] = useState("general");


	const handleTabsChange = useCallback((event: any, value: any) => {
		setCurrentTab(value);
	}, []);

	return (
		<>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth="xl">
					<Stack spacing={3} sx={{ mb: 3 }}>
						<Typography variant="h4">Settings</Typography>
						<div>
							<Tabs
								indicatorColor="primary"
								onChange={handleTabsChange}
								scrollButtons="auto"
								textColor="primary"
								value={currentTab}
								variant="scrollable"
							>
								{tabs.map((tab) => (
									<Tab key={tab.value} label={tab.label} value={tab.value} />
								))}
							</Tabs>
							<Divider />
						</div>
					</Stack>
					{currentTab === "general" && (
						<PrinterSettings />
					)}
					{currentTab === "billing" && (
						<>Billing</>
					)}
					{currentTab === "team" && (
						<>Team</>
					)}
					{currentTab === "notifications" &&
						<>Notification</>}
					{currentTab === "security" && (
						<>Security</>
					)}
				</Container>
			</Box>
		</>
	);
};

// Page.getLayout = (page) => <DashboardLayout>{page}</DashboardLayout>;

export default Page;
