import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { SideNav } from './sidenav';
import { TopNav } from './topnav';
import { Outlet } from 'react-router-dom';
import { useAuthContext } from '../../contexts/auth-context';
import { useQuery } from '@apollo/client';
import { GET_USER_CURRENT_SHOP_DETAILS } from '../../gql/queries';

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled('div')(({ theme }) => ({
	display: 'flex',
	flex: '1 1 auto',
	maxWidth: '100%',
	[theme.breakpoints.up('lg')]: {
		paddingLeft: SIDE_NAV_WIDTH
	}
}));

const LayoutContainer = styled('div')({
	display: 'flex',
	flex: '1 1 auto',
	flexDirection: 'column',
	width: '100%'
});

export const Layout = (props: any) => {

	const { jwtDecoded, shopId, locationId, setShopDetails } = useAuthContext();
	const { data } = useQuery(GET_USER_CURRENT_SHOP_DETAILS, { variables: { shopId, locationId, userId: jwtDecoded?.user_id } });
	const [openNav, setOpenNav] = useState(true);

	useEffect(() => {
		if (data && data.getUserCurrentShopDetails) {
			setShopDetails(data.getUserCurrentShopDetails);
		}
	}, [data]);

	useEffect(
		() => {
			if (openNav) {
				setOpenNav(false);
			}
		},
		[openNav]
	);

	return (
		<>
			<TopNav onNavOpen={() => setOpenNav(true)} />
			<SideNav
				onClose={() => setOpenNav(false)}
				open={openNav}
			/>
			<LayoutRoot>
				<LayoutContainer>
					<Outlet />
				</LayoutContainer>
			</LayoutRoot>
		</>
	);
};
