import { useFormik } from "formik";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	Unstable_Grid2 as Grid,
	Autocomplete,
} from "@mui/material";
import { toast } from "react-toastify";

import { useLazyQuery, useMutation } from "@apollo/client";
import GenericModal from "./generic.modal";
import { useEffect, useState } from "react";
import { Customer } from "../../gql/graphql";
import { useShopDetails } from "../../hooks/use-shop";
import {
	ADD_UPDATE_CUSTOMER,
	GET_CUSTOMER_BY_ID,
	SEARCH_CUSTOMER_BY_PHONE,
} from "../../gql/queries";

const validationSchema = yup.object({
	firstName: yup.string().required("First name is required"),
	email: yup
		.string()
		.email("Enter a valid email"),
	phone: yup
		.string()
		.min(10, "Phone number is too short")
		.max(10, "Phone number is too long")
		.required("Phone number is required"),
});

function EditCustomer(props: any) {
	const { data: customer, onClose, isPhoneSearchable } = props;

	const { shopId, locationId } = useShopDetails();

	const [getCustomerById] = useLazyQuery<Record<"customer", Customer>>(
		GET_CUSTOMER_BY_ID,
		{}
	);
	const [addUpdateCustomer, { loading }] = useMutation(ADD_UPDATE_CUSTOMER);

	const formik = useFormik<
		Partial<Omit<Customer, "dob"> & { dob: Date | null }>
	>({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			shopId,
			locationId,
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				const { data } = await addUpdateCustomer({
					variables: { customer: values },
				});
				const { firstName, lastName } = data.addUpdateCustomer;
				toast.success(
					`Customer: ${firstName} ${lastName} ${customer?._id ? "updated" : "created"
					} successfully.`
				);
				props.onClose(data.addUpdateCustomer);
			} catch (e: any) {
				toast.error(e.message);
			}
		},
	});

	useEffect(() => {
		if (customer?._id) {
			const setInitialValues = async () => {
				const { data } = await getCustomerById({
					variables: { id: customer?._id, shopId, locationId },
				});
				if (data?.customer) {
					formik.setValues({
						...formik.values,
						...data.customer,
						dob: data.customer.dob ? new Date(+data.customer.dob) : null,
					});
				}
			};
			setInitialValues();
		}
		// eslint-disable-next-line
	}, []);

	const [searchCustomerByPhoneNumber] = useLazyQuery<
		Record<"searchCustomerByPhoneNumber", Customer[]>
	>(SEARCH_CUSTOMER_BY_PHONE, {});

	const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);

	const [phoneNumberToCustomerMap, setPhoneNumberToCustomerMap] = useState<any>(
		{}
	);

	// add debounce
	// eslint-disable-next-line
	const handlePhoneNumberChange = async (event: any, value: any) => {
		if (event.type === "change") {
			if (value) {
				formik.setValues({
					...formik.values,
					_id: undefined,
					email: "",
					firstName: "",
					lastName: "",
					dob: null,
					phone: value,
				});
				const { data } = await searchCustomerByPhoneNumber({
					variables: { phone: value, shopId, locationId },
				});
				if (
					data?.searchCustomerByPhoneNumber &&
					data?.searchCustomerByPhoneNumber?.length > 0
				) {
					const phoneNumtoCustomerMap: any = {};
					const phoneNumbers = data?.searchCustomerByPhoneNumber?.map(
						(c: Customer) => {
							phoneNumtoCustomerMap[c.phone] = c;
							return c.phone;
						}
					);
					setPhoneNumberToCustomerMap(() => phoneNumtoCustomerMap);

					if (phoneNumbers) {
						setPhoneNumbers(phoneNumbers);
					}
				}
			}
		} else if (["click", "keydown"].includes(event.type)) {
			if (value) {
				const customer = phoneNumberToCustomerMap[value];
				if (customer) {
					formik.setValues({
						...formik.values,
						_id: customer._id,
						email: customer.email,
						phone: customer.phone,
						firstName: customer.firstName,
						lastName: customer.lastName,
						dob: customer.dob ? new Date(+customer.dob) : null,
					});
				}
			}
		}
	};

	const selectOrAddCustomer = async () => {
		if (formik?.values?._id) {
			props.onClose(formik.values);
		} else {
			await formik.submitForm();
		}
	};
	return (
		<GenericModal
			open={true}
			title={`${customer?._id ? "Edit" : "Add"} Customer`}
			close={props.onClose}
		>
			<form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
				<Card>
					<CardHeader subheader="The information can be edited" />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										id="firstName"
										label="First name"
										name="firstName"
										value={formik.values.firstName}
										onChange={formik.handleChange}
										error={
											formik.touched.firstName &&
											Boolean(formik.errors.firstName)
										}
										helperText={
											formik.touched.firstName && formik.errors.firstName
										}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										id="lastName"
										label="Last name"
										name="lastName"
										value={formik.values.lastName}
										onChange={formik.handleChange}
										error={
											formik.touched.lastName && Boolean(formik.errors.lastName)
										}
										helperText={
											formik.touched.lastName && formik.errors.lastName
										}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										id="email"
										label="Email"
										name="email"
										value={formik.values.email}
										onChange={formik.handleChange}
										error={formik.touched.email && Boolean(formik.errors.email)}
										helperText={formik.touched.email && formik.errors.email}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									{isPhoneSearchable ? (
										<Autocomplete
											options={phoneNumbers}
											value={formik.values.phone!}
											onInputChange={handlePhoneNumberChange}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Search Customer by phone number"
													variant="outlined"
												/>
											)}
										/>
									) : (
										<TextField
											fullWidth
											id="phone"
											label="Phone"
											name="phone"
											value={formik.values.phone}
											onChange={formik.handleChange}
											error={
												formik.touched.phone && Boolean(formik.errors.phone)
											}
											helperText={formik.touched.phone && formik.errors.phone}
										/>
									)}
								</Grid>
								<Grid xs={12} md={6}>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											label="Birthday"
											onChange={(value) => formik.setFieldValue("dob", value)}
											value={formik.values.dob}
										/>
									</LocalizationProvider>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />
					<CardActions sx={{ justifyContent: "flex-end" }}>
						<Button variant="outlined" disabled={loading} onClick={onClose}>
							Cancel
						</Button>
						{!isPhoneSearchable ? (
							<LoadingButton
								loading={loading}
								variant="contained"
								type="submit"
							>
								Save
							</LoadingButton>
						) : (
							<LoadingButton
								loading={loading}
								variant="contained"
								onClick={selectOrAddCustomer}
							>
								Add
							</LoadingButton>
						)}
					</CardActions>
				</Card>
			</form>
		</GenericModal>
	);
}

export default EditCustomer;
