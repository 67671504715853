import { SyntheticEvent, useState, useCallback, useMemo } from 'react';
import {
	Box,
	Card,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	SvgIcon,
	IconButton,
	Popover,
} from '@mui/material';

import EllipsisVerticalIcon from '@heroicons/react/24/solid/EllipsisVerticalIcon';
import PencilIcon from '@heroicons/react/24/solid/PencilIcon';
import TrashIcon from '@heroicons/react/24/solid/TrashIcon';


import { GenericSearch } from './generic.search';
import { Scrollbar } from '../layouts/scrollbar';
import { AlertDialogSlide } from './generic.confirm';
import { AddButtonComponent } from '../button';
import { NodataRow } from './nodata.row';
import { GenericMenuItem } from './generic.menu.item';

export interface Column {
	key: string;
	title: string;
	formatter?: (column: Column, data: any) => React.ReactNode;
}

interface GenericTableProps<T> {
	data: T[];
	title: string;
	columns: Column[];
	onDeselectAll?: () => void;
	onDeselectOne?: (item: T) => void;
	onPageChange?: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
	onRowsPerPageChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
	onSelectAll?: () => void;
	onSelectOne?: (item: T) => void;
	onDeleteOne?: (item: T) => void;
	page?: number;
	rowsPerPage?: number;
	selected?: any;
	addEditComponent?: any;
	refetch: any;
	showSelectCheckBox?: boolean;
	SearchComponent?: JSX.Element | null;
}

export const GenericTable = <T extends any>(props: GenericTableProps<T>) => {
	const {
		data = [],
		selected,
		columns,
		addEditComponent: AddEditComponent,
		title,
		refetch,
		SearchComponent
	} = props;


	const [open, setOpen] = useState<any>(null);

	const handleCloseMenu = () => {
		setOpen(null);
	};


	const handleOnAddEdit = useCallback((data: any) => {
		if (data) {
			setSelectedData(data);
		}
		setAddEdit(true);
	}, []);

	const [addEditFlag, setAddEdit] = useState<boolean>(false);
	const [selectedData, setSelectedData] = useState<T | null>(null);

	const handleOnClose = useCallback(() => {
		setAddEdit(false);
		refetch();
		// eslint-disable-next-line
	}, []);

	const [deleteFlag, setDelete] = useState<boolean>(false);
	const handleOnDelete = (data: T) => {
		if (data) {
			setSelectedData(data);
			setDelete(true);
		}
	};

	const handleOnDeleteAgree = useCallback(async () => {
		if (selectedData) {
			await props?.onDeleteOne?.(selectedData as T);
			setDelete(false);
		}
		// eslint-disable-next-line 
	}, [selectedData]);

	const [page, setPage] = useState(0);
	const handlePageChange = useCallback((event: any, value: any) => {
		setPage(value);
	}, []);

	const [rowsPerPage, setRowsPerPage] = useState(5);
	const handleRowsPerPageChange = useCallback(
		(event: any) => {
			setRowsPerPage(event.target.value);
		},
		[]
	);

	const [search, setSearch] = useState(null);
	const handleSearchOnChange = useCallback(
		(search: any) => {
			setSearch(search);
		},
		[]
	);

	const filtered = useMemo(() => {
		if (search) {
			const keys = data && data[0] ? Object.keys(data[0]) : null;
			const matchRegExp = new RegExp(search, "i");
			setPage(0);
			return data.filter((one: any) => {
				let isMatch = false;
				keys?.forEach((key) => {
					if (!['_id', 'createdAt'].includes(key) && !isMatch) {
						isMatch = matchRegExp.test(one[key] as string);
					}
				});
				return isMatch;
			});
		}
		return data;
	}, [search, data]);

	const dataToShow = useMemo(() => {
		const startIndex = page * rowsPerPage;
		const endIndex = startIndex + rowsPerPage;
		return filtered.slice(startIndex, endIndex);
	}, [filtered, page, rowsPerPage])


	return (
		<Stack spacing={3}>
			{addEditFlag && <AddEditComponent onClose={handleOnClose} data={selectedData} />}
			{deleteFlag && <AlertDialogSlide open={deleteFlag} onAgree={handleOnDeleteAgree} onDisagree={() => setDelete(false)} description='Are you sure want to delete ?' />}
			<Stack
				direction="row"
				justifyContent="space-between"
				spacing={4}
			>
				<Stack spacing={1}>
					<Typography variant="h4">
						{title}
					</Typography>
				</Stack>
				<AddButtonComponent onClick={handleOnAddEdit} />
			</Stack>
			{SearchComponent ? SearchComponent : <GenericSearch onChange={handleSearchOnChange} />}
			<Card>
				<Scrollbar>
					<Box sx={{ minWidth: 800 }}>
						<Table>
							<TableHead>
								<TableRow>
									{columns.map((column: Column) => (
										<TableCell key={column.key}>{column.title}</TableCell>
									))}
									<TableCell key='action'>Action</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ dataToShow.length === 0 && <NodataRow colSpan={columns.length + 2} /> }
								{dataToShow.map((row: any) => {
									const isSelected = selected && selected?._id === row._id ? true : false;
									return (
										<TableRow
											hover
											key={row['_id']}
											selected={isSelected}
										>
											{columns.map((column: Column) => (
												<TableCell key={column.key}>{column.formatter ? column.formatter(column, row) : row[column.key]}</TableCell>
											))}
											<TableCell key='action'>
												<IconButton size="large" color="inherit" onClick={(event: SyntheticEvent) => { (event.currentTarget as any).data = row; setOpen(event.currentTarget) }}>
													<SvgIcon
														color="action"
														fontSize="small"
													>
														<EllipsisVerticalIcon />
													</SvgIcon>
												</IconButton>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</Box>
				</Scrollbar>
				<Popover
					open={Boolean(open)}
					anchorEl={open}
					onClose={handleCloseMenu}
					anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
					PaperProps={{
						sx: {
							p: 1,
							width: 140,
							'& .MuiMenuItem-root': {
								px: 1,
								typography: 'body2',
								borderRadius: 0.75,
							},
						},
					}}
				>
					<GenericMenuItem onClick={() => { handleOnAddEdit(open?.data); }} icon={PencilIcon} label='Edit' />
					<GenericMenuItem onClick={() => { handleOnDelete(open?.data); }} icon={TrashIcon} label='Delete' />
				</Popover>
				<TablePagination
					component="div"
					count={filtered.length}
					onPageChange={handlePageChange}
					onRowsPerPageChange={handleRowsPerPageChange}
					page={page}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[5, 10, 25]}
				/>
			</Card>
		</Stack>
	);
};