import { gql } from "@apollo/client";

export const LIST_PRODUCTS = gql`
	query hairproducts($shopId: String!, $locationId: String!) {
		hairproducts(shopId: $shopId, locationId: $locationId) {
			_id
			name
			quantity
			price
			createdAt
		}
	}
`;

export const LIST_SERVICES = gql`
	query hairservices($shopId: String!, $locationId: String!) {
		hairservices(shopId: $shopId, locationId: $locationId) {
			_id
			name
			duration_minutes
			price
			createdAt
		}
	}
`;

export const LIST_ORDERS = gql`
	query orders($query: SearchInput!) {
		orders(query: $query) {
			_id
			sequenceNumber
			stylistUserName
			customerName
			paymentMethod
			createdAt
			total
		}
	}
`;

export const LIST_CUSTOMERS = gql`
	query customers($shopId: String!, $locationId: String!) {
		customers(shopId: $shopId, locationId: $locationId) {
			_id
			email
			phone
			firstName
			lastName
			createdAt
		}
	}
`;

export const DETELE_CUSTOMER = gql`
	mutation deleteCustomer($customerId: String!, $shopId: String!) {
		deleteCustomer(customerId: $customerId, shopId: $shopId)
	}
`;

export const DETELE_PRODUCT = gql`
	mutation deleteProduct($productId: String!) {
		deleteProduct(productId: $productId)
	}
`;

export const DETELE_SERVICE = gql`
	mutation deleteHairService($serviceId: String!) {
		deleteHairService(serviceId: $serviceId)
	}
`;

export const DELETE_ORDER = gql`
	mutation deleteOrder($orderId: String!) {
		deleteOrder(orderId: $orderId)
	}
`;

export const LIST_USERS = gql`
	query users($shopId: String!, $locationId: String!) {
		users(shopId: $shopId, locationId: $locationId) {
			_id
			email
			phone
			firstName
			lastName
			createdAt
		}
	}
`;

export const SEARCH_CUSTOMER_BY_PHONE = gql`
	query SearchCustomerByPhoneNumber($shopId: String!, $locationId: String!, $phone: String!) {
		searchCustomerByPhoneNumber(shopId: $shopId, locationId: $locationId, phone: $phone) {
			_id
			email
			phone
			firstName
			lastName
			createdAt
			dob
		}
	}
`;

export const DELELE_USER = gql`
	mutation deleteUser($userId: String!, $shopId: String!) {
		deleteUser(userId: $userId, shopId: $shopId)
	}
`;

export const GET_USER_BY_ID = gql`
	query User($id: String!, $shopId: String!, $locationId: String!) {
		user(userId: $id, shopId: $shopId, locationId: $locationId) {
			_id
			email
			phone
			firstName
			lastName
		}
	}
`;

export const ADD_UPDATE_USER = gql`
	mutation AddUpdateUser($user: UserInput!) {
		addUpdateUser(user: $user) {
			_id
			firstName
			lastName
		}
	}
`;
export const GET_ORDER_BY_ID = gql`
 query Order($id: String!) {
	 order(orderId: $id) {
			_id
			sequenceNumber
			stylistUserName
			customerName
			customerId
			stylistUserId
			createdAt
			paymentMethod
			total
			products {
				name
				quantity
				price
				productId
				total
				discountValue
				discountAmount
				discountType
				stylistUserId
				stylistUserName
			}
			services {  
				name
				quantity
				price
				serviceId
				total
				discountValue
				discountAmount
				discountType
				stylistUserId
				stylistUserName
			}
	 }
 }
`;


export const ADD_UPDATE_CUSTOMER = gql`
	mutation AddUpdateCustomer($customer: CustomerInput!) {
		addUpdateCustomer(customer: $customer) {
			_id
			firstName
			lastName
		}
	}
`;

export const GET_CUSTOMER_BY_ID = gql`
	query Customer($id: String!) {
		customer(customerId: $id) {
			_id
			email
			phone
			firstName
			lastName
			dob
		}
	}
`;

export const GET_USER_CURRENT_SHOP_DETAILS = gql`
	query UserDetails($userId: String!, $shopId: String!, $locationId: String!) {
		getUserCurrentShopDetails(userId: $userId, shopId: $shopId, locationId: $locationId) {
			name
			location {
				name
				phone
				address {
					street
					city
					state
					zipcode
					landmark
				}
			}
		}
	}
`;

export const LIST_EXPENSES = gql`
	query expenses($shopId: String!, $locationId: String!) {
		expenses(shopId: $shopId, locationId: $locationId) {
			_id
			name
			price
			createdAt
		}
	}
`;

export const DETELE_EXPENSE = gql`
	mutation deleteExpense($expenseId: String!) {
		deleteExpense(expenseId: $expenseId)
	}
`;

export const ADD_UPDATE_EXPENSE = gql`
	mutation addUpdateExpense($expense: ExpenseInput!) {
		addUpdateExpense(expense: $expense) {
			name
		}
	}
`;

export const GET_EXPENSE_BY_ID = gql`
	query expense($id: String!) {
		expense(_id: $id) {
			_id
			name
			price
			description
		}
	}
`;

export const GET_REPORT = gql`
	query report($query: SearchInput!) {
		report(query: $query) {
			totalSales
			totalProductSales
			totalServiceSales
			totalCustomers
			totalNewCustomers
			totalExistingCustomers
			orders {
				_id
				sequenceNumber
				total
				services {
					total
					discountAmount
					name
					quantity
				}
				products {
					total
					discountAmount
				}
			}
			paymentReport {
				upi
				card
				cash
			}
		}
	}
`;

export const ADD_UPDATE_ORDER = gql`
	mutation AddUpdateOrder($order: OrderInput!) {
		addUpdateOrder(order: $order) {
			_id
			sequenceNumber
			createdAt
		}
	}
`;

export const SAVE_PRINTER_NAME = gql`
	mutation SavePrinterName($shopId: String!, $locationId: String!, $printerName: String!) {
		savePrinterName(shopId: $shopId, locationId: $locationId, printerName: $printerName) 
	}
`;

export const GET_PRINTER_NAME = gql`
	query GetPrinterName($shopId: String!, $locationId: String!) {
		printerName(shopId: $shopId, locationId: $locationId)
	}
`;