import { useCallback, useEffect, useState } from 'react';

export const useSelection = (items = []) => {
	const [selected, setSelected] = useState(null);

	useEffect(() => {
		setSelected(null);
	}, [items]);

	const handleSelectAll = useCallback(() => {
		// setSelected();
		// eslint-disable-next-line
	}, [items]);

	const handleSelectOne = useCallback((item: any) => {
		setSelected((prevState: any) => item);
	}, []);

	const handleDeselectAll = useCallback(() => {
		setSelected(null);
	}, []);

	const handleDeselectOne = useCallback((item: any) => {
		setSelected(null)
	}, []);

	return {
		handleDeselectAll,
		handleDeselectOne,
		handleSelectAll,
		handleSelectOne,
		selected
	};
};
