/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  landmark: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street?: Maybe<Scalars['String']['output']>;
  zipcode: Scalars['String']['output'];
};

export type AddressInput = {
  city: Scalars['String']['input'];
  landmark: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street?: InputMaybe<Scalars['String']['input']>;
  zipcode: Scalars['String']['input'];
};

/** Customer object */
export type CheckinCheckout = {
  __typename?: 'CheckinCheckout';
  checkInOutId?: Maybe<Scalars['String']['output']>;
  checkinTime?: Maybe<Scalars['DateTimeISO']['output']>;
  checkoutTime?: Maybe<Scalars['DateTimeISO']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt: Scalars['DateTimeISO']['output'];
  locationId: Scalars['String']['output'];
  shopId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type CheckinCheckoutInput = {
  checkInOutId?: InputMaybe<Scalars['String']['input']>;
  checkinTime?: InputMaybe<Scalars['DateTimeISO']['input']>;
  checkoutTime?: InputMaybe<Scalars['DateTimeISO']['input']>;
  locationId: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

/** Customer object */
export type Customer = {
  __typename?: 'Customer';
  _id?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt: Scalars['DateTimeISO']['output'];
  dob?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  locationId: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  shopId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

/** Customer input */
export type CustomerInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
};

export enum DiscountType {
  Amount = 'AMOUNT',
  Percentage = 'PERCENTAGE'
}

export type Expense = {
  __typename?: 'Expense';
  _id?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  locationId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  shopId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type ExpenseInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  shopId: Scalars['String']['input'];
};

export type HairProduct = {
  __typename?: 'HairProduct';
  _id?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  locationId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  shopId: Scalars['String']['output'];
  type: ProductType;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type HairProductInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  shopId: Scalars['String']['input'];
};

export type HairService = {
  __typename?: 'HairService';
  _id?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  duration_minutes: Scalars['Float']['output'];
  locationId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  shopId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type HairServiceInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration_minutes: Scalars['Float']['input'];
  locationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  shopId: Scalars['String']['input'];
};

export type Location = {
  __typename?: 'Location';
  _id?: Maybe<Scalars['String']['output']>;
  address: Address;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  printerName: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type LocationBase = {
  _id?: InputMaybe<Scalars['String']['input']>;
  address: AddressInput;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  printerName: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCheckInOut: CheckinCheckout;
  addLocationToShop: Shop;
  addShop: Shop;
  addUpdateCustomer?: Maybe<Customer>;
  addUpdateExpense?: Maybe<Expense>;
  addUpdateHairProduct?: Maybe<HairProduct>;
  addUpdateHairService?: Maybe<HairService>;
  addUpdateOrder?: Maybe<Order>;
  addUpdateUser?: Maybe<User>;
  deleteCustomer?: Maybe<Scalars['Boolean']['output']>;
  deleteExpense?: Maybe<Scalars['Boolean']['output']>;
  deleteHairService?: Maybe<Scalars['Boolean']['output']>;
  deleteOrder?: Maybe<Scalars['Boolean']['output']>;
  deleteProduct?: Maybe<Scalars['Boolean']['output']>;
  deleteShop?: Maybe<Scalars['Boolean']['output']>;
  deleteUser?: Maybe<Scalars['Boolean']['output']>;
  generateOtp: Scalars['Boolean']['output'];
  login?: Maybe<Scalars['String']['output']>;
  savePrinterName: Scalars['String']['output'];
};


export type MutationAddCheckInOutArgs = {
  checkInOut: CheckinCheckoutInput;
};


export type MutationAddLocationToShopArgs = {
  shop: ShopInput;
};


export type MutationAddShopArgs = {
  shop: ShopInput;
};


export type MutationAddUpdateCustomerArgs = {
  customer: CustomerInput;
};


export type MutationAddUpdateExpenseArgs = {
  expense: ExpenseInput;
};


export type MutationAddUpdateHairProductArgs = {
  hairProduct: HairProductInput;
};


export type MutationAddUpdateHairServiceArgs = {
  hairService: HairServiceInput;
};


export type MutationAddUpdateOrderArgs = {
  order: OrderInput;
};


export type MutationAddUpdateUserArgs = {
  user: UserInput;
};


export type MutationDeleteCustomerArgs = {
  customerId: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
};


export type MutationDeleteExpenseArgs = {
  expenseId: Scalars['String']['input'];
};


export type MutationDeleteHairServiceArgs = {
  serviceId: Scalars['String']['input'];
};


export type MutationDeleteOrderArgs = {
  orderId: Scalars['String']['input'];
};


export type MutationDeleteProductArgs = {
  productId: Scalars['String']['input'];
};


export type MutationDeleteShopArgs = {
  shop_id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  shopId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationGenerateOtpArgs = {
  phone: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  otp: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationSavePrinterNameArgs = {
  locationId: Scalars['String']['input'];
  printerName: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
};

export type Order = {
  __typename?: 'Order';
  _id?: Maybe<Scalars['String']['output']>;
  billingUser: User;
  createdAt: Scalars['DateTimeISO']['output'];
  customerId: Scalars['String']['output'];
  customerName: Scalars['String']['output'];
  deletedAt: Scalars['DateTimeISO']['output'];
  locationId: Scalars['String']['output'];
  paymentMethod: Scalars['String']['output'];
  products: Array<OrderProduct>;
  sequenceNumber: Scalars['Float']['output'];
  services: Array<OrderService>;
  shopId: Scalars['String']['output'];
  stylistUserId?: Maybe<Scalars['String']['output']>;
  stylistUserName?: Maybe<Scalars['String']['output']>;
  total: Scalars['Float']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type OrderInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  customerId: Scalars['String']['input'];
  customerName: Scalars['String']['input'];
  locationId: Scalars['String']['input'];
  paymentMethod: Scalars['String']['input'];
  products: Array<OrderProductInput>;
  sequenceNumber?: InputMaybe<Scalars['Float']['input']>;
  services: Array<OrderServiceInput>;
  shopId: Scalars['String']['input'];
  stylistUserId?: InputMaybe<Scalars['String']['input']>;
  stylistUserName?: InputMaybe<Scalars['String']['input']>;
  total?: InputMaybe<Scalars['Float']['input']>;
};

export type OrderProduct = {
  __typename?: 'OrderProduct';
  _id?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountType?: Maybe<DiscountType>;
  discountValue?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  productId: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  stylistUserId?: Maybe<Scalars['String']['output']>;
  stylistUserName?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type OrderProductInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountType?: InputMaybe<DiscountType>;
  discountValue?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  productId: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
  stylistUserId?: InputMaybe<Scalars['String']['input']>;
  stylistUserName?: InputMaybe<Scalars['String']['input']>;
  total?: InputMaybe<Scalars['Float']['input']>;
};

export type OrderService = {
  __typename?: 'OrderService';
  _id?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountType?: Maybe<DiscountType>;
  discountValue?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  serviceId: Scalars['String']['output'];
  stylistUserId?: Maybe<Scalars['String']['output']>;
  stylistUserName?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type OrderServiceInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountType?: InputMaybe<DiscountType>;
  discountValue?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  serviceId: Scalars['String']['input'];
  stylistUserId?: InputMaybe<Scalars['String']['input']>;
  stylistUserName?: InputMaybe<Scalars['String']['input']>;
  total?: InputMaybe<Scalars['Float']['input']>;
};

export type PaymentReport = {
  __typename?: 'PaymentReport';
  card: Scalars['Float']['output'];
  cash: Scalars['Float']['output'];
  upi: Scalars['Float']['output'];
};

export enum ProductType {
  HairCare = 'HAIR_CARE',
  SkinCare = 'SKIN_CARE',
  Unknown = 'Unknown'
}

export type Query = {
  __typename?: 'Query';
  customer: Customer;
  customers: Array<Customer>;
  expense?: Maybe<Expense>;
  expenses: Array<Expense>;
  getUserCurrentShopDetails: Shop;
  getUserShopAccess: Array<Shop>;
  hairproduct?: Maybe<HairProduct>;
  hairproducts: Array<HairProduct>;
  hairservice?: Maybe<HairService>;
  hairservices: Array<HairService>;
  listCheckInOut: Array<CheckinCheckout>;
  order: Order;
  orders: Array<Order>;
  printerName: Scalars['String']['output'];
  report: Report;
  searchCustomerByPhoneNumber: Array<Customer>;
  shops: Array<Shop>;
  user?: Maybe<User>;
  users: Array<User>;
  verifyjwt: Scalars['Boolean']['output'];
};


export type QueryCustomerArgs = {
  customerId: Scalars['String']['input'];
};


export type QueryCustomersArgs = {
  locationId: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
};


export type QueryExpenseArgs = {
  _id: Scalars['String']['input'];
};


export type QueryExpensesArgs = {
  locationId: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
};


export type QueryGetUserCurrentShopDetailsArgs = {
  locationId: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QueryHairproductArgs = {
  _id: Scalars['String']['input'];
};


export type QueryHairproductsArgs = {
  locationId: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
};


export type QueryHairserviceArgs = {
  _id: Scalars['String']['input'];
};


export type QueryHairservicesArgs = {
  locationId: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
};


export type QueryOrderArgs = {
  orderId: Scalars['String']['input'];
};


export type QueryOrdersArgs = {
  locationId: Scalars['String']['input'];
  query?: InputMaybe<SearchInput>;
  shopId: Scalars['String']['input'];
};


export type QueryPrinterNameArgs = {
  locationId: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
};


export type QueryReportArgs = {
  query: SearchInput;
};


export type QuerySearchCustomerByPhoneNumberArgs = {
  locationId: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
};


export type QueryUserArgs = {
  locationId: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  locationId: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
};

export type Report = {
  __typename?: 'Report';
  orders: Array<Order>;
  paymentReport: PaymentReport;
  totalCustomers: Scalars['Float']['output'];
  totalDiscount: Scalars['Float']['output'];
  totalExistingCustomers: Scalars['Float']['output'];
  totalNewCustomers: Scalars['Float']['output'];
  totalProductDiscount: Scalars['Float']['output'];
  totalProductSales: Scalars['Float']['output'];
  totalSales: Scalars['Float']['output'];
  totalServiceDiscount: Scalars['Float']['output'];
  totalServiceSales: Scalars['Float']['output'];
};

export type SearchInput = {
  endDate: Scalars['String']['input'];
  locationId: Scalars['String']['input'];
  shopId: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  stylistUserId?: InputMaybe<Scalars['String']['input']>;
};

export type Shop = {
  __typename?: 'Shop';
  _id?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt: Scalars['DateTimeISO']['output'];
  location: Array<Location>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type ShopInput = {
  location: Array<LocationBase>;
  name: Scalars['String']['input'];
  shopId?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  roles: Array<UserRole>;
  shopId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type UserInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  roles: Array<UserRoleInput>;
  shopId: Scalars['String']['input'];
};

export type UserRole = {
  __typename?: 'UserRole';
  locationId?: Maybe<Scalars['String']['output']>;
  role: UserType;
};

export type UserRoleInput = {
  locationId?: InputMaybe<Scalars['String']['input']>;
  role: UserType;
};

export enum UserType {
  LocationAdmin = 'LOCATION_ADMIN',
  LocationBillingUser = 'LOCATION_BILLING_USER',
  LocationUser = 'LOCATION_USER',
  ShopAdmin = 'SHOP_ADMIN',
  SuperAdmin = 'SUPER_ADMIN'
}

export type AddUpdateHairProductMutationVariables = Exact<{
  hairProduct: HairProductInput;
}>;


export type AddUpdateHairProductMutation = { __typename?: 'Mutation', addUpdateHairProduct?: { __typename?: 'HairProduct', name: string } | null };

export type HairproductQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type HairproductQuery = { __typename?: 'Query', hairproduct?: { __typename?: 'HairProduct', _id?: string | null, name: string, price: number, quantity: number, description: string } | null };

export type AddUpdateHairServiceMutationVariables = Exact<{
  hairService: HairServiceInput;
}>;


export type AddUpdateHairServiceMutation = { __typename?: 'Mutation', addUpdateHairService?: { __typename?: 'HairService', name: string } | null };

export type HairserviceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type HairserviceQuery = { __typename?: 'Query', hairservice?: { __typename?: 'HairService', _id?: string | null, name: string, price: number, duration_minutes: number, description: string } | null };

export type JwtMutationVariables = Exact<{
  phone: Scalars['String']['input'];
  otp: Scalars['String']['input'];
}>;


export type JwtMutation = { __typename?: 'Mutation', login?: string | null };

export type GenerateOptMutationVariables = Exact<{
  phone: Scalars['String']['input'];
}>;


export type GenerateOptMutation = { __typename?: 'Mutation', generateOtp: boolean };

export type GetUserShopAccessQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserShopAccessQuery = { __typename?: 'Query', getUserShopAccess: Array<{ __typename?: 'Shop', _id?: string | null, name: string, location: Array<{ __typename?: 'Location', _id?: string | null, address: { __typename?: 'Address', city: string } }> }> };


export const AddUpdateHairProductDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddUpdateHairProduct"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"hairProduct"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"HairProductInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addUpdateHairProduct"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"hairProduct"},"value":{"kind":"Variable","name":{"kind":"Name","value":"hairProduct"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<AddUpdateHairProductMutation, AddUpdateHairProductMutationVariables>;
export const HairproductDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"hairproduct"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hairproduct"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"price"}},{"kind":"Field","name":{"kind":"Name","value":"quantity"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}}]}}]} as unknown as DocumentNode<HairproductQuery, HairproductQueryVariables>;
export const AddUpdateHairServiceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddUpdateHairService"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"hairService"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"HairServiceInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addUpdateHairService"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"hairService"},"value":{"kind":"Variable","name":{"kind":"Name","value":"hairService"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<AddUpdateHairServiceMutation, AddUpdateHairServiceMutationVariables>;
export const HairserviceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"hairservice"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hairservice"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"price"}},{"kind":"Field","name":{"kind":"Name","value":"duration_minutes"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}}]}}]} as unknown as DocumentNode<HairserviceQuery, HairserviceQueryVariables>;
export const JwtDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"jwt"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"phone"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"otp"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"login"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"phone"},"value":{"kind":"Variable","name":{"kind":"Name","value":"phone"}}},{"kind":"Argument","name":{"kind":"Name","value":"otp"},"value":{"kind":"Variable","name":{"kind":"Name","value":"otp"}}}]}]}}]} as unknown as DocumentNode<JwtMutation, JwtMutationVariables>;
export const GenerateOptDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateOPT"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"phone"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateOtp"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"phone"},"value":{"kind":"Variable","name":{"kind":"Name","value":"phone"}}}]}]}}]} as unknown as DocumentNode<GenerateOptMutation, GenerateOptMutationVariables>;
export const GetUserShopAccessDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserShopAccess"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUserShopAccess"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"location"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"address"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"city"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetUserShopAccessQuery, GetUserShopAccessQueryVariables>;