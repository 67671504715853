import { useMemo, useState } from "react";
import { Box, Container, Grid, Button, SvgIcon, CardActions } from "@mui/material";
import { useShopDetails } from "../../hooks/use-shop";
import { useQuery } from "@apollo/client";
import { GET_REPORT } from "../../gql/queries";
import { ReportStats } from "../../components/report/stats";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Order, OrderProduct, OrderService, Report } from "../../gql/graphql";
import { ReportDonut } from "../../components/report/donut";
import { useSearch } from "../../hooks/use-search";
import { GenericModalV2 } from "../../components/generic/generic.modal";
import { Column, GenericTable } from "../../components/generic/generic.table";
import {
	Card,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	IconButton,
	Popover,
} from '@mui/material';
import { NodataRow } from "../../components/generic/nodata.row";
import { Scrollbar } from '../../components/layouts/scrollbar';

const ReportsPage = () => {
	const { shopId, locationId } = useShopDetails();

	const { SearchComponent, searchQuery } = useSearch();

	const [showModal, setShowModal] = useState(false);

	const { data } = useQuery<Record<"report", Report>>(GET_REPORT, {
		variables: {
			query: { shopId, locationId, startDate: searchQuery.from, endDate: searchQuery.to, stylistUserId: searchQuery.userId },
		},
	});

	const {
		totalSales,
		totalProductSales,
		totalServiceSales,
		orders,
		totalCustomers,
		totalNewCustomers,
		totalExistingCustomers,
		paymentReport,
	} = data?.report || {
		totalSales: 0,
		totalProductSales: 0,
		totalServiceSales: 0,
		totalCustomers: 0,
		totalNewCustomers: 0,
		totalExistingCustomers: 0,
		paymentReport: {
			cash: 0,
			card: 0,
			upi: 0,
		},
		orders: [],
	};

	const serviceProductAmounts = useMemo(() => {
		let productAmountList: number[] = [];
		let serviceAmountList: number[] = [];
		orders?.forEach((order: Order) => {
			try {
				order.services.forEach((service: OrderService) => {
					serviceAmountList.push((service?.total! * service?.quantity!) - service?.discountAmount!);
				});
				order.products.forEach((product: OrderProduct) => {
					productAmountList.push(product?.total! - product?.discountAmount!);
				});
			} catch (error) {
				console.log(error);
			}
		});
		return [productAmountList, serviceAmountList];
	}, [orders]);

	const getServiceTable = () => {
		const columns: Column[] = [
			{ title: "Service", key: "name" },
			{ title: "Count", key: "count" },
			{ title: "Total", key: "total" },
		];

		const data: any = [];
		orders?.forEach((order: Order) => {
			try {
				order.services.forEach((service: OrderService) => {
					const index = data.findIndex((d: any) => d.name === service.name);
					if (index > -1) {
						data[index].count += 1;
						data[index].total += (service?.total! * service?.quantity!) - service?.discountAmount!;
					} else {
						data.push({ name: service.name, count: 1, total: (service?.total! * service?.quantity!) - service?.discountAmount!});
					}
				});
			} catch (error) {
				console.log(error);
			}
		})
		return (
			<Stack spacing={3}>
				<CardActions sx={{ justifyContent: "flex-end" }}>
					<Button variant="outlined" onClick={()=>setShowModal(false)}>
						Close
					</Button>
				</CardActions>
				<Card>
					<Scrollbar>
						<Box sx={{ minWidth: 800 }}>
							<Table>
								<TableHead>
									<TableRow>
										{columns.map((column: Column) => (
											<TableCell key={column.key}>{column.title}</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{data.length === 0 && <NodataRow colSpan={columns.length + 2} />}
									{data.map((row: any) => {
										return (
											<TableRow
												hover
												key={row['_id']}
											>
												{columns.map((column: Column) => (
													<TableCell key={column.key}>{column.formatter ? column.formatter(column, row) : row[column.key]}</TableCell>
												))}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</Box>
					</Scrollbar>
				</Card>
			</Stack>
		);
	}

	return (
		<>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container>
					{SearchComponent}
				</Container>

				<Grid container>
					<Grid xs={12} md={4}>
						<ReportStats
							action={
								<Button
									color="inherit"
									endIcon={
										<SvgIcon>
											<ArrowRightIcon />
										</SvgIcon>
									}
									size="small"
								>
									Total Sales
								</Button>
							}
							chartSeries={[
								{
									data: orders.map((order: any) => order.total),
								},
							]}
							title="Total Sales"
							value={`₹${totalSales}`}
						/>
					</Grid>
					<Grid xs={12} md={4}>
						<ReportStats
							action={
								<Button
									onClick={() => setShowModal(true)}
									color="inherit"
									endIcon={
										<SvgIcon>
											<ArrowRightIcon />
										</SvgIcon>
									}
									size="small"
								>
									Service Sales
								</Button>
							}
							chartSeries={[
								{
									data: serviceProductAmounts[1],
								},
							]}
							title="Service Sales"
							value={`₹${totalServiceSales}`}
						/>
					</Grid>
					<Grid xs={12} md={4}>
						<ReportStats
							action={
								<Button
									color="inherit"
									endIcon={
										<SvgIcon>
											<ArrowRightIcon />
										</SvgIcon>
									}
									size="small"
								>
									Product Sales
								</Button>
							}
							chartSeries={[
								{
									data: serviceProductAmounts[0],
								},
							]}
							title="Product Sales"
							value={`₹${totalProductSales}`}
						/>
					</Grid>
				</Grid>

				<Grid container>
					<Grid xs={12} md={4}>
						<ReportStats
							action={
								<Button
									onClick={() => setShowModal(true)}
									color="inherit"
									endIcon={
										<SvgIcon>
											<ArrowRightIcon />
										</SvgIcon>
									}
									size="small"
								>
									Service Sales
								</Button>
							}
							chartSeries={[
								{
									data: [],
								},
							]}
							title="Service Sales"
							value={`${orders.length}`}
						/>
					</Grid>
					<Grid xs={12} md={4}>
						<ReportStats
							action={
								<Button
									color="inherit"
									endIcon={
										<SvgIcon>
											<ArrowRightIcon />
										</SvgIcon>
									}
									size="small"
								>
									Total Customers
								</Button>
							}
							chartSeries={[
								{
									data: serviceProductAmounts[1],
								},
							]}
							title="Total Customers"
							value={`${totalCustomers}`}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid xs={12} md={4}>
						<ReportDonut
							title="New vs Old Customers"
							chartSeries={[totalNewCustomers, totalExistingCustomers]}
							labels={["New Customers", "Old Customers"]}
						/>
					</Grid>
					<Grid xs={12} md={4}>
						<ReportDonut
							title="Payment Reports"
							chartSeries={[
								paymentReport.cash,
								paymentReport.card,
								paymentReport.upi,
							]}
							labels={[
								`Cash ₹${paymentReport.cash}`,
								`Card ₹${paymentReport.card}`,
								`UPI ₹${paymentReport.upi}`,
							]}
						/>
					</Grid>
				</Grid>
				{showModal && <GenericModalV2 open={true} title="Services" >
					{getServiceTable()}
				</GenericModalV2>}
			</Box>
		</>
	);
};

export default ReportsPage;
