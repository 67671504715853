import { useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Box, Container } from '@mui/material';
import { toast } from 'react-toastify';


import { useSelection } from '../../hooks/use-selection';
import { GenericTable, Column } from '../../components/generic/generic.table';

import { useMutation, useQuery } from '@apollo/client';
import { DATE_FORMAT } from '../../constants';
import EditHairService from '../../components/generic/edit.hairservice';
import { HairService } from '../../gql/graphql';
import { DETELE_SERVICE, LIST_SERVICES } from '../../gql/queries';
import { useShopDetails } from '../../hooks/use-shop';

const columns: Column[] = [
	{ title: 'Name', key: 'name' },
	{ title: 'Duration', key: 'duration_minutes' },
	{ title: 'Price', key: 'price' },
	{ title: 'Created', key: 'createdAt', formatter: (column: Column, data: any) => { return format(new Date(data[column.key]), DATE_FORMAT); } },
];

const useCustomerIds = (customers: any) => {
	return useMemo(
		() => {
			return customers.map((customer: any) => customer.id);
		},
		[customers]
	);
};
function HairServiceList() {
	const { shopId, locationId } = useShopDetails();
	const { data, refetch } = useQuery(LIST_SERVICES, { variables: { shopId, locationId } });

	const hairservices: HairService[] = useMemo(
		() => {
			return data?.hairservices ? data?.hairservices : []
		},
		[data]
	);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	// const customers = useCustomers(page, rowsPerPage, data?.listCustomer);
	const customersIds = useCustomerIds(hairservices);
	const customersSelection = useSelection(customersIds);

	const handlePageChange = useCallback(
		(event: any, value: any) => {
			setPage(value);
		},
		[]
	);

	const handleRowsPerPageChange = useCallback(
		(event: any) => {
			setRowsPerPage(event.target.value);
		},
		[]
	);

	const [deleteService] = useMutation(DETELE_SERVICE);
	const handleDeleteOne = useCallback(
		async (service: HairService) => {
			await deleteService({ variables: { serviceId: service._id } });
			toast.success(`Deleted ${service.name} successfully.`);
			await refetch();
		},
		// eslint-disable-next-line
		[]
	);

	return (
		<>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8
				}}
			>
				<Container maxWidth="xl">
					<GenericTable
						title='Hair Services'
						data={hairservices}
						columns={columns}
						onDeselectAll={customersSelection.handleDeselectAll}
						onDeselectOne={customersSelection.handleDeselectOne}
						onPageChange={handlePageChange}
						onRowsPerPageChange={handleRowsPerPageChange}
						onSelectAll={customersSelection.handleSelectAll}
						onSelectOne={customersSelection.handleSelectOne}
						onDeleteOne={handleDeleteOne}
						page={page}
						rowsPerPage={rowsPerPage}
						selected={customersSelection.selected}
						addEditComponent={EditHairService}
						refetch={refetch}
					/>
				</Container>
			</Box>
		</>
	);
}

export default HairServiceList;
