import { useState } from 'react';
import { useMutation, gql, ApolloError } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../contexts/auth-context';

interface OtpProps {
	handleOnSubmit: () => void
	phone: string;
}

const AUTHENTICATE_WITH_OTP = gql`
mutation jwt($phone: String!, $otp: String!) {
  login(phone: $phone, otp: $otp)
}
`;

export const Otp = (props: OtpProps) => {
	const { setJwt } = useAuthContext();
	const [doLogin, { loading }] = useMutation(AUTHENTICATE_WITH_OTP);
	const [otp, setOtp] = useState('');

	const handleChange = (newValue: any) => {
		setOtp(newValue);
	};

	const handleOnClick = async () => {
		try {
			const { data: { login } } = await doLogin({ variables: { phone: props.phone, otp } });
			setJwt(login);
			props.handleOnSubmit();
		} catch (e) {
			toast.error((e as ApolloError).message);
		}
	};

	return (
		<>
			<MuiOtpInput value={otp} onChange={handleChange} />
			<LoadingButton
				fullWidth
				size="large"
				sx={{ mt: 3 }}
				type="submit"
				variant="contained"
				onClick={handleOnClick}
				loading={loading}
			>
				Login
			</LoadingButton>
		</>
	)
}
