import { useFormik } from "formik";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	TextareaAutosize,
	Unstable_Grid2 as Grid,
} from "@mui/material";
import { toast } from "react-toastify";

import { useLazyQuery, useMutation, gql } from "@apollo/client";
import GenericModal from "./generic.modal";
import { useEffect } from "react";
import { HairProduct } from "../../gql/graphql";
import { useShopDetails } from "../../hooks/use-shop";

const ADD_UPDATE_HAIRPRODUCT = gql`
	mutation AddUpdateHairProduct($hairProduct: HairProductInput!) {
		addUpdateHairProduct(hairProduct: $hairProduct) {
			name
		}
	}
`;

const GET_HAIRPRODUCT_BY_ID = gql`
	query hairproduct($id: String!) {
		hairproduct(_id: $id) {
			_id
			name
			price
			quantity
			description
		}
	}
`;

const validationSchema = yup.object({
	name: yup.string().required("Name is required"),
	price: yup.number().min(1).required("Price is required"),
	quantity: yup.number().min(0).required("Duration is required"),
});

function EditHairProduct(props: any) {
	const { data: hairProduct, onClose } = props;

	const { shopId, locationId } = useShopDetails();

	const [getHairProductById] = useLazyQuery<Record<"hairproduct", HairProduct>>(
		GET_HAIRPRODUCT_BY_ID,
		{}
	);
	const [addUpdateHairProduct, { loading }] = useMutation(
		ADD_UPDATE_HAIRPRODUCT
	);

	const formik = useFormik<Partial<HairProduct>>({
		initialValues: {
			name: "",
			price: 0,
			quantity: 0,
			description: "",
			shopId,
			locationId,
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				const { data } = await addUpdateHairProduct({
					variables: { hairProduct: values },
				});
				const { name } = data.addUpdateHairProduct;
				toast.success(
					`Product: ${name} ${hairProduct?._id ? "updated" : "created"
					} successfully.`
				);
				props.onClose();
			} catch (e: any) {
				toast.error(e.message);
			}
		},
	});

	useEffect(() => {
		if (hairProduct?._id) {
			const setInitialValues = async () => {
				const { data } = await getHairProductById({
					variables: { id: hairProduct?._id },
				});
				if (data?.hairproduct) {
					formik.setValues({
						...formik.values,
						...data.hairproduct,
					});
				}
			};
			setInitialValues();
		}
		// eslint-disable-next-line
	}, []);

	return (
		<GenericModal
			open={true}
			title={`${hairProduct?._id ? "Edit" : "Add"} Hair Product`}
			close={props.onClose}
		>
			<form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
				<Card>
					<CardHeader subheader="The information can be edited" />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										id="name"
										label="Product Name"
										name="name"
										value={formik.values.name}
										onChange={formik.handleChange}
										error={formik.touched.name && Boolean(formik.errors.name)}
										helperText={formik.touched.name && formik.errors.name}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										id="price"
										label="Price"
										name="price"
										type="number"
										value={formik.values.price}
										onChange={formik.handleChange}
										error={formik.touched.price && Boolean(formik.errors.price)}
										helperText={formik.touched.price && formik.errors.price}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										id="quantity"
										label="Quantity"
										name="quantity"
										type="number"
										value={formik.values.quantity}
										onChange={formik.handleChange}
										error={
											formik.touched.quantity && Boolean(formik.errors.quantity)
										}
										helperText={
											formik.touched.quantity && formik.errors.quantity
										}
									/>
								</Grid>
								<Grid xs={12} md={12}>
									<TextareaAutosize
										id="description"
										name="description"
										value={formik.values.description}
										onChange={formik.handleChange}
										style={{ width: "100%", height: "200%" }}
										placeholder="Description"
									// error={formik.touched.name && Boolean(formik.errors.name)}
									// helperText={formik.touched.name && formik.errors.name}
									/>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />
					<CardActions sx={{ justifyContent: "flex-end" }}>
						<Button variant="outlined" disabled={loading} onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton loading={loading} variant="contained" type="submit">
							Save
						</LoadingButton>
					</CardActions>
				</Card>
			</form>
		</GenericModal>
	);
}

export default EditHairProduct;
