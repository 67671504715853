
import {
	MenuItem as MuiMenuItem, SvgIcon,
} from '@mui/material';

interface GenericMenuItemProps {
	onClick:
		| ((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void)
		| undefined;
	icon: React.ElementType;
	label: string;
}

export const GenericMenuItem = ({ onClick, icon: Icon, label }: GenericMenuItemProps) => {
	return (
		<MuiMenuItem onClick={onClick}>
			<SvgIcon
				color="action"
				fontSize="small"
			>
				<Icon />
			</SvgIcon>
			{label}
		</MuiMenuItem>
	)
}