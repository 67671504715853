import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import "react-toastify/dist/ReactToastify.css";

import "simplebar-react/dist/simplebar.min.css";
import "./App.css";

import { createTheme } from "./theme";
import Router from "./Routes";
import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider,
	createHttpLink,
	DefaultOptions,
} from "@apollo/client";
import { AuthProvider } from "./contexts/auth-context";
import { setContext } from "@apollo/client/link/context";
import { OrderProvider } from "./contexts/order-context";

const httpLink = createHttpLink({
	uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem("jwt");
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
		},
	};
});

const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: "no-cache",
		errorPolicy: "ignore",
	},
	query: {
		fetchPolicy: "no-cache",
		errorPolicy: "all",
	},
};

export const apolloClient = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache({ addTypename: false }),
	defaultOptions,
});

function App() {
	return (
		<Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
			<div className="hairways-root">
				<BrowserRouter>
					<ApolloProvider client={apolloClient}>
						<AuthProvider>
							<OrderProvider>
								<ToastContainer theme="colored" />
								<ThemeProvider theme={createTheme()}>
									<CssBaseline />
									<Router />
								</ThemeProvider>
							</OrderProvider>
						</AuthProvider>
					</ApolloProvider>
				</BrowserRouter>
			</div>
		</Sentry.ErrorBoundary>
	);
}

export default App;
