import { useFormik } from 'formik';
import * as yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	TextareaAutosize,
	Unstable_Grid2 as Grid
} from '@mui/material';
import { toast } from 'react-toastify';

import { useLazyQuery, useMutation, gql } from '@apollo/client';
import GenericModal from './generic.modal';
import { useEffect } from 'react';
import { HairService } from '../../gql/graphql';
import { useShopDetails } from '../../hooks/use-shop';


const ADD_UPDATE_HAIRSERVICE = gql`
mutation AddUpdateHairService($hairService: HairServiceInput!) {
	addUpdateHairService(hairService: $hairService) {
		name
	}
}
`;

const GET_SERVIE_BY_ID = gql`
query hairservice($id: String!) {
	hairservice(_id: $id) {
		_id
		name
		price
		duration_minutes
		description
	}
}`;

const validationSchema = yup.object({
	name: yup
		.string()
		.required('Name is required'),
	price: yup
		.number()
		.min(1)
		.required('Price is required'),
	duration_minutes: yup
		.number()
		.min(1)
		.required('Duration is required'),
});

function EditHairService(props: any) {
	const { data: hairservice, onClose } = props;
	const { shopId, locationId } = useShopDetails();

	const [getHairServiceById] = useLazyQuery<Record<'hairservice', HairService>>(GET_SERVIE_BY_ID, {});
	const [addUpdateHairService, { loading }] = useMutation(ADD_UPDATE_HAIRSERVICE);

	const formik = useFormik<Partial<HairService>>({
		initialValues: {
			name: "",
			price: 0,
			duration_minutes: 0,
			description: "",
			shopId,
			locationId,
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				const { data } = await addUpdateHairService({ variables: { hairService: { ...values, shopId, locationId } } });
				const { name } = data.addUpdateHairService;
				toast.success(`Product: ${name} ${hairservice?._id ? "updated" : "created"} successfully.`);
				props.onClose();
			} catch (e: any) {
				toast.error(e.message);
			}
		},
	});

	useEffect(() => {
		if (hairservice?._id) {
			const setInitialValues = async () => {
				const { data } = await getHairServiceById({ variables: { id: hairservice?._id } });
				if (data?.hairservice) {
					formik.setValues({
						...formik.values,
						...data.hairservice
					});
				}
			}
			setInitialValues();
		}
		// eslint-disable-next-line
	}, []);

	return (
		<GenericModal open={true} title={`${hairservice?._id ? "Edit" : "Add"} Hair Service`} close={props.onClose}>
			<form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
				<Card>
					<CardHeader
						subheader="The information can be edited"
					/>
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid
								container
								spacing={3}
							>
								<Grid
									xs={12}
									md={12}
								>
									<TextField
										fullWidth
										id="name"
										label="Service Name"
										name="name"
										value={formik.values.name}
										onChange={formik.handleChange}
										error={formik.touched.name && Boolean(formik.errors.name)}
										helperText={formik.touched.name && formik.errors.name}
									/>
								</Grid>
								<Grid
									xs={12}
									md={6}
								>
									<TextField
										fullWidth
										id="price"
										label="Price"
										name="price"
										type='number'
										value={formik.values.price}
										onChange={formik.handleChange}
										error={formik.touched.price && Boolean(formik.errors.price)}
										helperText={formik.touched.price && formik.errors.price}
									/>
								</Grid>
								<Grid
									xs={12}
									md={6}
								>
									<TextField
										fullWidth
										id="duration_minutes"
										label="Duration ( In minutes )"
										name="duration_minutes"
										type='number'
										value={formik.values.duration_minutes}
										onChange={formik.handleChange}
										error={formik.touched.duration_minutes && Boolean(formik.errors.duration_minutes)}
										helperText={formik.touched.duration_minutes && formik.errors.duration_minutes}
									/>
								</Grid>
								<Grid
									xs={12}
									md={12}
								>
									<TextareaAutosize
										id="description"
										name="description"
										value={formik.values.description}
										onChange={formik.handleChange}
										style={{ width: '100%', height: '200%' }}
										placeholder="Description"
									// error={formik.touched.name && Boolean(formik.errors.name)}
									// helperText={formik.touched.name && formik.errors.name}
									/>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />
					<CardActions sx={{ justifyContent: 'flex-end' }}>
						<Button variant="outlined" disabled={loading} onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton loading={loading} variant="contained" type="submit" >
							Save
						</LoadingButton>
					</CardActions>
				</Card>
			</form>
		</GenericModal>
	);
}

export default EditHairService;
